.dropdown-toggle::after {
  filter: unset;
}

#searchForm {
  .select2 {
    width: 100% !important;
  }
  @include media-breakpoint-down(lg) {
    .row {
      .col-lg-3,
      .col-lg-4 {
        margin-bottom: 1rem;
      }
    }
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    line-height: unset;
    font-size: 1rem;
    color: $secondary;
  }
  .select2-container .select2-selection--single,
  .flatpickr-input {
    margin-top: -2px;
  }
  .select2-container .select2-selection--single {
    border: 1px solid $almost-grey;
    border-radius: 0px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    background-image: url("../images/dropdown_arrow.svg");
    background-color: transparent;
    background-size: contain;
    border: none !important;
    height: 1rem !important;
    width: 1rem !important;
    margin: auto !important;
    top: auto !important;
    left: auto !important;
  }
  .select2-selection__placeholder {
    color: $secondary !important;
  }
  .form-control {
    padding: 1.125rem 1rem;
    line-height: 1.125rem;
    font-size: 1rem;
    border: 1px solid $almost-grey;
    border-radius: 0px;
    color: $secondary;
  }
  .form-control:focus {
    box-shadow: none;
  }
  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $black;
    margin-bottom: 0.25rem;
  }
}
#priceSlider,
#daySlider {
  margin: 1.5rem 0;
  @include media-breakpoint-down(lg) {
    margin: 1.5rem 0 0;
  }
  .noUi-connects {
    background: $almost-grey !important;
    height: 2px;
  }
  .noUi-connect {
    background: $primary !important;
    height: 2px !important;
  }
  .noUi-handle-upper {
    right: -0.5rem;
  }
  .noUi-handle {
    top: -0.75rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50%;
    border: 2px solid $primary;
    &::before,
    &::after {
      display: none;
    }
  }
  background: $white;
  border: none;
  box-shadow: none;
}

.search-dropdown {
  button {
    text-align: left;
    color: $secondary;
  }
}
.travelers-dropdown-list.show {
  display: flex;
  margin: 0 -0.75rem;
  li {
    margin: 0 0.75rem;
  }
}
.travelers-dropdown-list {
  .input-group {
    input {
      padding: 0 1rem !important;
    }
  }
}

#searchCollapseLink[aria-expanded="true"], #hotelInfoCollapseLink[aria-expanded="true"] {
  svg {
    transform: rotate(180deg);
  }
}
#searchCollapseLink, #hotelInfoCollapseLink {
  svg path {
    fill: $secondary;
  }
}

#searchCollapseLink {
  @include media-breakpoint-up(sm) {
    margin-left: 1.5rem;
  }
}

.select2-dropdown {
  box-shadow: 0px 0px 16px 1px rgba(162, 162, 162, 0.12);
  border-radius: 4px;
  border: none;
}
.select2-container--default .select2-results__option--selected {
  background-color: $light-orange;
}
.collapse-container {
  padding: 0;
}

#purchaseForm {
  .select2-container {
    width: 100% !important;
  }
  .select2-selection__rendered,
  .select2-selection__arrow {
    margin: 0;
  }
  .select2-container--default .select2-selection--multiple {
    padding: 1rem;
    border: 1px solid $almost-grey;
  }
  .select2-search__field {
    margin: 0;
    font-size: 1rem;
    line-height: 1.125rem;
  }
  .select2-selection__choice {
    background-color: $light-orange;
    border: 1px solid $almost-grey;
  }
}
