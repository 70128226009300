.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0; }

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option--selectable {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative; }

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--group {
  padding: 0; }

.select2-container--default .select2-results__option--disabled {
  color: #999; }

.select2-container--default .select2-results__option--selected {
  background-color: #ddd; }

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option--group {
  padding: 0; }

.select2-container--classic .select2-results__option--disabled {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/*!
   * Bootstrap  v5.2.2 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #17b9bc;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #0e0e0e;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ff7a05;
  --bs-secondary: #626161;
  --bs-success: #17b9bc;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 255, 122, 5;
  --bs-secondary-rgb: 98, 97, 97;
  --bs-success-rgb: 23, 185, 188;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 14, 14, 14;
  --bs-body-color-rgb: 98, 97, 97;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #626161;
  --bs-body-bg: #ffffff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(14, 14, 14, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #ff7a05;
  --bs-link-hover-color: #cc6204;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(14, 14, 14, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid, img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl,
.container-xxxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1280px; } }

@media (min-width: 1860px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl {
    max-width: 1760px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1860px) {
  .col-xxxl {
    flex: 1 0 0%; }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0; }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0; }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(14, 14, 14, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(14, 14, 14, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(14, 14, 14, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #ffe4cd;
  --bs-table-border-color: #e7cfba;
  --bs-table-striped-bg: #f3d9c3;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #e7cfba;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #edd4bf;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #e0dfdf;
  --bs-table-border-color: #cbcaca;
  --bs-table-striped-bg: #d6d5d5;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #cbcaca;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #d0cfcf;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #d1f1f2;
  --bs-table-border-color: #bedadb;
  --bs-table-striped-bg: #c7e6e7;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #bedadb;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #c2e0e1;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #bcdde4;
  --bs-table-striped-bg: #c5e9f0;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #bcdde4;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #c1e3ea;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e7dcba;
  --bs-table-striped-bg: #f3e8c3;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #e7dcba;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #ede2bf;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #e1c3c6;
  --bs-table-striped-bg: #eccdd0;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #e1c3c6;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #e6c8cb;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #0e0e0e;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #e1e2e2;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #0e0e0e;
  --bs-table-active-bg: #e1e2e2;
  --bs-table-active-color: #0e0e0e;
  --bs-table-hover-bg: #e6e7e8;
  --bs-table-hover-color: #0e0e0e;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1859.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #626161;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #626161;
    background-color: #ffffff;
    border-color: #ffbd82;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 122, 5, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #626161;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #626161;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.375rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.375rem; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #626161;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #ffbd82;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 122, 5, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #626161; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(14, 14, 14, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #ffbd82;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 122, 5, 0.25); }
  .form-check-input:checked {
    background-color: #ff7a05;
    border-color: #ff7a05; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #ff7a05;
    border-color: #ff7a05;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2814, 14, 14, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffbd82'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(255, 122, 5, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(255, 122, 5, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ff7a05;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #ffd7b4; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff7a05;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #ffd7b4; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #626161;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #17b9bc; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #0e0e0e;
  background-color: rgba(23, 185, 188, 0.9);
  border-radius: 0.375rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #17b9bc;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2317b9bc' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #17b9bc;
    box-shadow: 0 0 0 0.25rem rgba(23, 185, 188, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #17b9bc; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2317b9bc' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #17b9bc;
    box-shadow: 0 0 0 0.25rem rgba(23, 185, 188, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #17b9bc; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #17b9bc; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(23, 185, 188, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #17b9bc; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #626161;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(14, 14, 14, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #0e0e0e;
  --bs-btn-bg: #ff7a05;
  --bs-btn-border-color: #ff7a05;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #ff8e2b;
  --bs-btn-hover-border-color: #ff871e;
  --bs-btn-focus-shadow-rgb: 219, 106, 6;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #ff9537;
  --bs-btn-active-border-color: #ff871e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #0e0e0e;
  --bs-btn-disabled-bg: #ff7a05;
  --bs-btn-disabled-border-color: #ff7a05; }

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #626161;
  --bs-btn-border-color: #626161;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #535252;
  --bs-btn-hover-border-color: #4e4e4e;
  --bs-btn-focus-shadow-rgb: 122, 121, 121;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4e4e4e;
  --bs-btn-active-border-color: #4a4949;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #626161;
  --bs-btn-disabled-border-color: #626161; }

.btn-success {
  --bs-btn-color: #0e0e0e;
  --bs-btn-bg: #17b9bc;
  --bs-btn-border-color: #17b9bc;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #3ac4c6;
  --bs-btn-hover-border-color: #2ec0c3;
  --bs-btn-focus-shadow-rgb: 22, 159, 162;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #45c7c9;
  --bs-btn-active-border-color: #2ec0c3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #0e0e0e;
  --bs-btn-disabled-bg: #17b9bc;
  --bs-btn-disabled-border-color: #17b9bc; }

.btn-info {
  --bs-btn-color: #0e0e0e;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 13, 174, 206;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #0e0e0e;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --bs-btn-color: #0e0e0e;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 219, 166, 8;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #0e0e0e;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #0e0e0e;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 213, 214, 215;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #0e0e0e;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529; }

.btn-outline-primary {
  --bs-btn-color: #ff7a05;
  --bs-btn-border-color: #ff7a05;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #ff7a05;
  --bs-btn-hover-border-color: #ff7a05;
  --bs-btn-focus-shadow-rgb: 255, 122, 5;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #ff7a05;
  --bs-btn-active-border-color: #ff7a05;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #ff7a05;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff7a05;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #626161;
  --bs-btn-border-color: #626161;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #626161;
  --bs-btn-hover-border-color: #626161;
  --bs-btn-focus-shadow-rgb: 98, 97, 97;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #626161;
  --bs-btn-active-border-color: #626161;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #626161;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #626161;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #17b9bc;
  --bs-btn-border-color: #17b9bc;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #17b9bc;
  --bs-btn-hover-border-color: #17b9bc;
  --bs-btn-focus-shadow-rgb: 23, 185, 188;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #17b9bc;
  --bs-btn-active-border-color: #17b9bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #17b9bc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17b9bc;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #0e0e0e;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #0e0e0e;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(14, 14, 14, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 219, 106, 6;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #626161;
  --bs-dropdown-bg: #ffffff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #ff7a05;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1860px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start; }
    .dropdown-menu-xxxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxxl-end {
    --bs-position: end; }
    .dropdown-menu-xxxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #ff7a05;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 0.375rem; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #ffffff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #ff7a05; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(14, 14, 14, 0.55);
  --bs-navbar-hover-color: rgba(14, 14, 14, 0.7);
  --bs-navbar-disabled-color: rgba(14, 14, 14, 0.3);
  --bs-navbar-active-color: rgba(14, 14, 14, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(14, 14, 14, 0.9);
  --bs-navbar-brand-hover-color: rgba(14, 14, 14, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2814, 14, 14, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(14, 14, 14, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl, .navbar > .container-xxxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1860px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(14, 14, 14, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #626161;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #626161;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23626161'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e66e05'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #ffbd82;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(255, 122, 5, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #e66e05;
  --bs-accordion-active-bg: #fff2e6; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #ffffff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(255, 122, 5, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #ff7a05;
  --bs-pagination-active-border-color: #ff7a05;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #ffffff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem; }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #994903;
  --bs-alert-bg: #ffe4cd;
  --bs-alert-border-color: #ffd7b4; }
  .alert-primary .alert-link {
    color: #7a3a02; }

.alert-secondary {
  --bs-alert-color: #3b3a3a;
  --bs-alert-bg: #e0dfdf;
  --bs-alert-border-color: #d0d0d0; }
  .alert-secondary .alert-link {
    color: #2f2e2e; }

.alert-success {
  --bs-alert-color: #0e6f71;
  --bs-alert-bg: #d1f1f2;
  --bs-alert-border-color: #b9eaeb; }
  .alert-success .alert-link {
    color: #0b595a; }

.alert-info {
  --bs-alert-color: #0e5968;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb; }
  .alert-info .alert-link {
    color: #0b4753; }

.alert-warning {
  --bs-alert-color: #6e560b;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #584509; }

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  --bs-alert-color: #6c6c6c;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #565656; }

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(14, 14, 14, 0.075);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #ff7a05;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #ffffff;
  --bs-list-group-border-color: rgba(14, 14, 14, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #626161;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #ffffff;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #ff7a05;
  --bs-list-group-active-border-color: #ff7a05;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1860px) {
  .list-group-horizontal-xxxl {
    flex-direction: row; }
    .list-group-horizontal-xxxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #994903;
  background-color: #ffe4cd; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #994903;
    background-color: #e6cdb9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #994903;
    border-color: #994903; }

.list-group-item-secondary {
  color: #3b3a3a;
  background-color: #e0dfdf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3b3a3a;
    background-color: #cac9c9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #3b3a3a;
    border-color: #3b3a3a; }

.list-group-item-success {
  color: #0e6f71;
  background-color: #d1f1f2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0e6f71;
    background-color: #bcd9da; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #0e6f71;
    border-color: #0e6f71; }

.list-group-item-info {
  color: #0e5968;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0e5968;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #0e5968;
    border-color: #0e5968; }

.list-group-item-warning {
  color: #6e560b;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #6e560b;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #6e560b;
    border-color: #6e560b; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #6c6c6c;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #6c6c6c;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #6c6c6c;
    border-color: #6c6c6c; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #141619;
    border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #0e0e0e;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230e0e0e'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #0e0e0e;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 122, 5, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(14, 14, 14, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(14, 14, 14, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #0e0e0e;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1859.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxxl-down .modal-header,
    .modal-fullscreen-xxxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #ffffff;
  --bs-tooltip-bg: #0e0e0e;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #626161;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #0e0e0e; }

.carousel-dark .carousel-caption {
  color: #0e0e0e; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas-xxxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #ffffff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(14, 14, 14, 0.075); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1859.98px) {
  .offcanvas-xxxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1859.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxxl {
      transition: none; } }

@media (max-width: 1859.98px) {
    .offcanvas-xxxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxxl.showing, .offcanvas-xxxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxxl.showing, .offcanvas-xxxl.hiding, .offcanvas-xxxl.show {
      visibility: visible; } }

@media (min-width: 1860px) {
  .offcanvas-xxxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxxl .offcanvas-header {
      display: none; }
    .offcanvas-xxxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #0e0e0e; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #0e0e0e 55%, rgba(0, 0, 0, 0.8) 75%, #0e0e0e 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #0e0e0e !important;
  background-color: RGBA(255, 122, 5, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(98, 97, 97, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #0e0e0e !important;
  background-color: RGBA(23, 185, 188, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #0e0e0e !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #0e0e0e !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #0e0e0e !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #ff7a05 !important; }
  .link-primary:hover, .link-primary:focus {
    color: #ff9537 !important; }

.link-secondary {
  color: #626161 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #4e4e4e !important; }

.link-success {
  color: #17b9bc !important; }
  .link-success:hover, .link-success:focus {
    color: #45c7c9 !important; }

.link-info {
  color: #0dcaf0 !important; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3 !important; }

.link-warning {
  color: #ffc107 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39 !important; }

.link-danger {
  color: #dc3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #f8f9fa !important; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb !important; }

.link-dark {
  color: #212529 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1860px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(14, 14, 14, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(14, 14, 14, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(14, 14, 14, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(14, 14, 14, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1860px) {
  .float-xxxl-start {
    float: left !important; }
  .float-xxxl-end {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-grid {
    display: grid !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; }
  .d-xxxl-none {
    display: none !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; }
  .order-xxxl-first {
    order: -1 !important; }
  .order-xxxl-0 {
    order: 0 !important; }
  .order-xxxl-1 {
    order: 1 !important; }
  .order-xxxl-2 {
    order: 2 !important; }
  .order-xxxl-3 {
    order: 3 !important; }
  .order-xxxl-4 {
    order: 4 !important; }
  .order-xxxl-5 {
    order: 5 !important; }
  .order-xxxl-last {
    order: 6 !important; }
  .m-xxxl-0 {
    margin: 0 !important; }
  .m-xxxl-1 {
    margin: 0.25rem !important; }
  .m-xxxl-2 {
    margin: 0.5rem !important; }
  .m-xxxl-3 {
    margin: 1rem !important; }
  .m-xxxl-4 {
    margin: 1.5rem !important; }
  .m-xxxl-5 {
    margin: 3rem !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxxl-0 {
    margin-top: 0 !important; }
  .mt-xxxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxxl-3 {
    margin-top: 1rem !important; }
  .mt-xxxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxxl-5 {
    margin-top: 3rem !important; }
  .mt-xxxl-auto {
    margin-top: auto !important; }
  .me-xxxl-0 {
    margin-right: 0 !important; }
  .me-xxxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxxl-3 {
    margin-right: 1rem !important; }
  .me-xxxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxxl-5 {
    margin-right: 3rem !important; }
  .me-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxxl-auto {
    margin-bottom: auto !important; }
  .ms-xxxl-0 {
    margin-left: 0 !important; }
  .ms-xxxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxxl-3 {
    margin-left: 1rem !important; }
  .ms-xxxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxxl-5 {
    margin-left: 3rem !important; }
  .ms-xxxl-auto {
    margin-left: auto !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .p-xxxl-1 {
    padding: 0.25rem !important; }
  .p-xxxl-2 {
    padding: 0.5rem !important; }
  .p-xxxl-3 {
    padding: 1rem !important; }
  .p-xxxl-4 {
    padding: 1.5rem !important; }
  .p-xxxl-5 {
    padding: 3rem !important; }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxxl-0 {
    padding-top: 0 !important; }
  .pt-xxxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxxl-3 {
    padding-top: 1rem !important; }
  .pt-xxxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxxl-5 {
    padding-top: 3rem !important; }
  .pe-xxxl-0 {
    padding-right: 0 !important; }
  .pe-xxxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxxl-3 {
    padding-right: 1rem !important; }
  .pe-xxxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxxl-5 {
    padding-right: 3rem !important; }
  .pb-xxxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxxl-0 {
    padding-left: 0 !important; }
  .ps-xxxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxxl-3 {
    padding-left: 1rem !important; }
  .ps-xxxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxxl-5 {
    padding-left: 3rem !important; }
  .gap-xxxl-0 {
    gap: 0 !important; }
  .gap-xxxl-1 {
    gap: 0.25rem !important; }
  .gap-xxxl-2 {
    gap: 0.5rem !important; }
  .gap-xxxl-3 {
    gap: 1rem !important; }
  .gap-xxxl-4 {
    gap: 1.5rem !important; }
  .gap-xxxl-5 {
    gap: 3rem !important; }
  .text-xxxl-start {
    text-align: left !important; }
  .text-xxxl-end {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat; }

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px; }

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px; }

.send-btn {
  background: #ff7a05;
  padding: 1rem;
  border: none;
  border-radius: 0px 0.25rem 0.25rem 0px; }

.search-btn {
  background: #ffffff;
  color: #0e0e0e !important;
  font-weight: 500;
  padding: 1rem;
  border: none;
  border-radius: 0px 0.25rem 0.25rem 0px;
  line-height: 1.5rem;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0; }
  .search-btn:hover {
    color: #ffffff;
    background: #fff4ea; }
  .search-btn img {
    max-width: none; }

.more-btn {
  background: #ff7a05;
  color: #ffffff;
  padding: 0.875rem 0;
  display: block;
  margin: 0 auto 1rem;
  max-width: 11.875rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center; }
  @media (min-width: 768px) {
    .more-btn {
      display: none; } }
  @media (max-width: 767.98px) {
    .more-btn {
      margin-top: 1rem; } }

.return-btn {
  display: inline-block;
  background: #ff7a05;
  border: 1px solid #ff7a05;
  color: #ffffff;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 0.875rem 2rem;
  margin-top: 2rem; }

.send-inquiry-btn {
  display: block;
  width: 100%;
  background: #ff7a05;
  line-height: 1.25rem;
  color: #ffffff;
  font-weight: 500;
  padding: 0.875rem 0; }
  .send-inquiry-btn:hover {
    background: #ff7a05;
    color: #ffffff; }

.download-btn {
  background: #fff4ea;
  font-weight: 500;
  line-height: 1.25rem;
  color: #ff7a05;
  padding: 0.875rem 1rem;
  display: inline-block; }
  .download-btn img {
    margin-right: 0.5rem; }

.save-btn {
  padding: 0.875rem 0.5rem;
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #17b9bc;
  border-radius: 0.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  color: #17b9bc;
  margin-top: 0.5rem; }

.next-step-btn {
  padding: 0.875rem 0.5rem;
  display: block;
  width: 100%;
  background: #ff7a05;
  border: 1px solid #ff7a05;
  border-radius: 0.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-top: 1.5rem; }
  .next-step-btn:hover {
    color: #ffffff; }
  .next-step-btn a {
    color: #ffffff; }

.buy-btn {
  background: #ffffff;
  display: inline-block;
  min-width: 12rem;
  padding: 0.75rem 2rem;
  border: 1px solid #ff7a05;
  border-radius: 0.25rem;
  color: #ff7a05;
  line-height: 1.25rem; }
  .buy-btn:hover svg path {
    fill: #0e0e0e; }

.submit-query-btn {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  background: #ff7a05;
  color: #ffffff;
  border-radius: 0.25rem;
  padding: 0.875rem 0.5rem;
  margin-left: auto;
  display: block;
  width: 50%;
  border: none; }
  @media (max-width: 575.98px) {
    .submit-query-btn {
      width: 100%; } }

.social-btn {
  padding: 0.75rem 1.5rem;
  color: #17b9bc;
  border: 1px solid #17b9bc;
  border-radius: 0.25rem;
  font-weight: 500;
  display: inline-block; }
  .social-btn svg {
    margin-right: 0.5rem; }
  .social-btn:hover svg path {
    fill: #0e0e0e; }

.search-result-btn {
  padding: 1rem 1.25rem;
  display: block;
  background: #ff7a05;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  width: 25%;
  margin: 0;
  border: 0; }
  .search-result-btn svg {
    margin-left: 0.5rem; }
  @media (max-width: 991.98px) {
    .search-result-btn {
      width: 50%;
      margin: 1.5rem 0; } }
  @media (max-width: 575.98px) {
    .search-result-btn {
      width: 100%; } }

.order-btn {
  background: #ff7a05;
  display: block;
  width: 100%;
  color: #ffffff;
  text-align: center;
  border: 1px solid #ff7a05;
  border-radius: 0.25rem;
  padding: 0.875rem 0.5rem;
  margin: 1rem 0 0.5rem; }

.header-banner {
  background-color: #fbfbfb;
  padding: 0.75rem 0;
  width: 100%; }
  .header-banner p,
  .header-banner .language-code {
    margin: 0 0 0 2rem;
    font-size: 0.875rem;
    color: #373737; }
  .header-banner .language-code {
    line-height: 1rem; }
  @media (max-width: 1399.98px) {
    .header-banner {
      display: none; } }

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  background: #ffffff; }

.navbar-main {
  padding: 1.125rem 0;
  width: 100%;
  display: flex;
  justify-content: end;
  flex-wrap: nowrap !important; }
  @media (max-width: 1399.98px) {
    .navbar-main {
      padding: 1.125rem 1.5rem;
      margin: 0;
      max-width: 100%; } }

.navbar-nav {
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1.25rem; }
  @media (max-width: 1399.98px) {
    .navbar-nav {
      display: none; } }

.nav-link {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #0e0e0e;
  margin: 0 1.25rem; }

.navbar-nav .dropdown-menu {
  position: absolute;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12); }

.dropdown-item:hover {
  background-color: #fff4ea; }

.dropdown-menu.drop-lang {
  left: 1.875rem;
  margin-top: 0.125rem;
  border: 0;
  border-radius: 0;
  min-width: unset;
  border-top: 1px solid #dfdfdf; }

.icon-container {
  display: flex;
  margin: 0 0 0 1.5rem;
  align-items: center; }
  .icon-container svg {
    margin-right: 1.5rem; }
  @media (max-width: 1399.98px) {
    .icon-container {
      margin: 0;
      min-width: 7.5rem;
      align-items: flex-end; }
      .icon-container svg {
        margin-right: 0; } }

.search-mobile-icon svg {
  margin: 0 1.5rem; }
  @media (min-width: 1400px) {
    .search-mobile-icon svg {
      display: none; } }

.search-link {
  border: 1px solid #17b9bc;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  color: #17b9bc;
  font-weight: 500;
  line-height: 1.25rem;
  display: flex; }
  .search-link span {
    text-align: center; }
  .search-link svg {
    margin-right: 0.5rem; }
  .search-link:hover svg path {
    fill: #0e0e0e; }
  @media (max-width: 1399.98px) {
    .search-link {
      display: none; } }

.hero-image {
  width: 100%;
  object-fit: cover;
  max-width: unset;
  object-fit: cover; }
  @media (max-width: 991.98px) {
    .hero-image {
      min-height: 41.375rem; } }

.dropdown-toggle::after {
  border: none;
  content: url("../images/dropdown_arrow.svg");
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  filter: brightness(0) saturate(100%); }

.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg); }

.dropdown-toggle:after {
  transition: 0.3s; }

#savedTrips.dropdown-toggle::after {
  display: none; }

#savedTrips + .dropdown-menu {
  margin-top: 1.5rem; }

.trips-collapse {
  top: 10rem !important;
  right: 0 !important;
  left: unset !important;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 1rem 1px rgba(140, 140, 140, 0.12); }
  .trips-collapse svg {
    margin: 0; }
  @media (max-width: 1399.98px) {
    .trips-collapse {
      top: 7rem !important; } }
  @media (min-width: 576px) {
    .trips-collapse {
      max-width: 28.75rem;
      border-radius: 0.5rem 0px 0px 0px; } }
  @media (max-width: 575.98px) {
    .trips-collapse {
      transition: none; } }
  @media (max-width: 575.98px) {
    .trips-collapse {
      top: 0 !important;
      bottom: 0 !important; } }

#savedPlansMenu .next-step-btn {
  margin: 1.5rem 2rem;
  width: unset; }
  @media (max-width: 575.98px) {
    #savedPlansMenu .next-step-btn {
      bottom: -0.5rem;
      position: fixed;
      width: calc(100% - 3.25rem);
      margin: 1.5rem; } }

@media (max-width: 575.98px) {
  #savedPlansMenu {
    max-height: 85%;
    overflow-y: auto; } }

.saved-trips {
  padding: 1.5rem 2rem 0.5rem;
  overflow-y: scroll;
  scrollbar-color: #626161;
  scrollbar-width: thin; }
  @media (min-width: 576px) {
    .saved-trips {
      max-height: 50vh; } }
  @media (max-width: 575.98px) {
    .saved-trips {
      max-height: 85%;
      padding: 1.5rem 1.5rem 0.5rem; } }
  .saved-trips::-webkit-scrollbar {
    width: 0.4rem;
    position: absolute;
    top: 0; }
  .saved-trips::-webkit-scrollbar-track {
    border-radius: 0.875rem; }
  .saved-trips::-webkit-scrollbar-thumb {
    background-color: #626161;
    border-radius: 0.875rem; }
  .saved-trips::-webkit-scrollbar-thumb:hover {
    background: #626161; }

hr {
  color: #ececec;
  opacity: 1;
  width: 100%; }

.saved-img {
  border-radius: 0.5rem 0.5rem 0px 0px;
  position: relative;
  z-index: 0; }

.travel-img-container {
  position: absolute;
  top: 1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: -0.5rem; }

.travel-img {
  padding: 0.25rem;
  background: #edfafa;
  border-radius: 0.25rem; }
  .travel-img svg:not(:last-child) {
    margin-right: 0.5rem; }
  .travel-img svg path {
    fill: #17b9bc; }

.saved-circle,
.save-circle {
  background: rgba(255, 255, 255, 0.64);
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.offcanvas {
  background: #ffffff;
  border-radius: 0.5rem 0px 0px 0.5rem; }

.offcanvas-end {
  width: max-content !important; }

#destinationCollapseLink[aria-expanded="true"] img,
#languageCollapseLink[aria-expanded="true"]:not(:first-of-type) img {
  transform: rotate(180deg); }

#destinationCollapseLink img {
  filter: brightness(0) saturate(100%);
  transition: 0.3s; }

.important-menu-links a {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #0e0e0e; }
  .important-menu-links a:not(:last-child) {
    margin-bottom: 1.25rem; }

.important-menu-links,
.information-links {
  display: flex;
  flex-direction: column; }

#destinationCollapse a {
  display: block;
  margin-bottom: 1rem;
  margin-left: 0.5rem; }

.information-links {
  margin-top: 2rem; }
  .information-links a {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #373737;
    padding-bottom: 1rem; }

.save-circle.active svg path {
  fill: #ff7a05; }

.saved-circle.active svg path {
  fill: none; }

#dropdownMenuLink {
  align-items: flex-end; }

@media (min-width: 1400px) {
  .header-mobile-icon {
    display: none; } }

.footer-notes {
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .footer-notes p,
  .footer-notes a {
    margin: 0;
    font-size: 0.875rem;
    color: #373737; }
  .footer-notes p:not(:last-child) {
    margin-right: 0.75rem; }
  @media (max-width: 991.98px) {
    .footer-notes {
      padding: 1.5rem 0 0;
      margin-top: 2.5rem; }
      .footer-notes p,
      .footer-notes a {
        margin-bottom: 1rem; } }
  @media (max-width: 767.98px) {
    .footer-notes {
      flex-direction: column;
      margin: 2.5rem 0 0;
      padding: 1.5rem 1.5rem 0;
      max-width: 100%; } }

.footer-container {
  padding: 5.5rem 0 4.125rem; }
  .footer-container .row .col-md-6 {
    display: flex;
    flex-direction: column; }
    @media (max-width: 991.98px) {
      .footer-container .row .col-md-6 {
        margin-bottom: 3rem; } }
    .footer-container .row .col-md-6 p:not(:last-child),
    .footer-container .row .col-md-6 a:not(:last-child),
    .footer-container .row .col-md-6 h4:not(:last-child),
    .footer-container .row .col-md-6 .h4:not(:last-child) {
      padding-bottom: 0.75rem;
      margin: 0; }
    @media (max-width: 575.98px) {
      .footer-container .row .col-md-6:last-child {
        margin-bottom: 3rem; } }
  @media (max-width: 991.98px) {
    .footer-container {
      margin: 0;
      padding: 5.5rem 1.5rem 4.125rem;
      max-width: 100%; } }

.form-inline {
  display: flex; }

#newsletterForm {
  max-width: 18.75rem;
  margin: 1rem 0 2.75rem; }
  #newsletterForm input {
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 0.25rem 0px 0px 0.25rem;
    padding: 1.125rem 1rem; }
  @media (max-width: 991.98px) {
    #newsletterForm {
      margin: 1rem 0 0; } }

.social-block {
  position: absolute;
  bottom: 0;
  display: block; }

.newsletter-info {
  margin: 2.75rem 0 0.25rem; }

.services-container {
  padding: 3.5rem 0 5.5rem; }
  .services-container h1, .services-container .h1 {
    margin-bottom: 3.5rem; }
  @media (max-width: 991.98px) {
    .services-container {
      padding: 3.5rem 1.5rem 5.5rem;
      margin: 0;
      max-width: 100%; }
      .services-container h1, .services-container .h1 {
        margin-bottom: 2.5rem; } }
  .services-container span {
    color: #373737;
    display: block; }

.service-page-card {
  padding: 1.25rem 1rem 2rem;
  background: #ffffff;
  border-radius: 0.5rem;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative; }
  @media (max-width: 575.98px) {
    .service-page-card {
      padding: 1.25rem 1rem 0; } }
  .service-page-card h4, .service-page-card .h4 {
    color: #ff7a05;
    margin-top: 1rem;
    margin-bottom: 2.25rem;
    text-align: center; }
  .service-page-card svg {
    margin-bottom: 1rem; }
  .service-page-card p {
    text-align: center; }
    .service-page-card p:not(:last-child) {
      margin: 0; }
  .service-page-card:hover > img {
    transition: all 0.5s; }
  @media (min-width: 768px) {
    .service-page-card:hover {
      background: #fff4ea; }
      .service-page-card:hover .more-btn {
        margin-bottom: 0; }
      .service-page-card:hover img {
        width: 6rem;
        height: 6rem; }
      .service-page-card:hover p {
        display: none; }
      .service-page-card:hover .more-btn {
        display: block;
        color: #ffffff; } }

.service-text-container {
  max-height: 390px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal; }

.personal-offer-col {
  background: #17b9bc;
  padding: 5rem 5rem 5.5rem;
  border-radius: 0px 0.5rem 0.5rem 0px; }
  .personal-offer-col span,
  .personal-offer-col p,
  .personal-offer-col a {
    color: #fbfbfb; }
  .personal-offer-col h2, .personal-offer-col .h2 {
    color: #ffffff;
    margin: 1.5rem 0 0.5rem; }
  @media (max-width: 991.98px) {
    .personal-offer-col {
      padding: 2rem 1rem 4rem;
      border-radius: 0px 0px 0.5rem 0.5rem; } }

.worker-image {
  border-radius: 50%;
  max-width: 4rem;
  max-height: 4rem;
  object-fit: cover;
  margin: 0 0.5rem 0.5rem 0; }
  @media (max-width: 991.98px) {
    .worker-image {
      margin: 0 0.5rem 2rem 0; } }

#offerForm {
  margin: 1rem 0 0; }
  #offerForm .select2-selection__rendered {
    padding-right: 70px; }
  @media (max-width: 991.98px) {
    #offerForm .select2-container {
      width: 100% !important; } }
  #offerForm .select2-selection {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 0.25rem 0px 0px 0.25rem;
    border-color: transparent; }
  #offerForm .select2-selection__placeholder,
  #offerForm .select2-selection__rendered {
    color: #ffffff;
    line-height: unset; }
  #offerForm .select2-selection__arrow {
    display: none; }
  @media (max-width: 991.98px) {
    #offerForm {
      margin: 1rem 0 0; } }
  #offerForm .form-control::-webkit-input-placeholder {
    color: #ffffff; }
  @media (max-width: 991.98px) {
    #offerForm {
      max-width: 100%; } }

.offer-section-img,
.page-section-img {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem 0px 0px 0.5rem; }
  @media (max-width: 991.98px) {
    .offer-section-img,
    .page-section-img {
      object-fit: cover;
      max-height: 18.75rem;
      border-radius: 0.5rem 0.5rem 0px 0px; } }

.page-section-img {
  max-height: 322px;
  object-fit: cover;
  border-radius: 0 0.5rem 0.5rem 0; }

.services-inner-content {
  margin-bottom: 5.5rem; }
  .services-inner-content h3, .services-inner-content .h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    color: #17b9bc;
    margin-bottom: 1rem; }
  .services-inner-content h3, .services-inner-content .h3,
  .services-inner-content h4,
  .services-inner-content .h4,
  .services-inner-content h5,
  .services-inner-content .h5,
  .services-inner-content h6,
  .services-inner-content .h6 {
    color: #17b9bc; }
  .services-inner-content p {
    margin-bottom: 2rem; }
  .services-inner-content a {
    color: #ff7a05;
    font-weight: 500; }

.services-list li {
  padding-left: 0.5rem; }
  .services-list li:not(:last-child) {
    margin-bottom: 1rem; }

.service-card {
  padding: 1.5rem;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s; }
  .service-card p {
    color: #ff7a05;
    font-weight: 500;
    line-height: 1.2rem;
    text-align: center;
    margin: 0.5rem 0 0; }
  .service-card svg {
    margin-bottom: 1rem; }
  @media (min-width: 768px) {
    .service-card:hover {
      background: #fff4ea; } }

.all-service-row .col-6 {
  margin-bottom: 1.5rem; }

.service-cards-container {
  padding: 5.5rem 0; }
  @media (max-width: 991.98px) {
    .service-cards-container {
      margin: 0;
      max-width: 100%;
      padding: 4rem 1.5rem; } }
  .service-cards-container h2, .service-cards-container .h2 {
    margin-bottom: 2.5rem; }

.service-pricing-table {
  margin-bottom: 2.5rem;
  border-collapse: collapse; }
  .service-pricing-table tr {
    border-radius: 0.5rem 0.5rem 0px 0px; }
  .service-pricing-table h4, .service-pricing-table .h4 {
    color: #17b9bc;
    margin: 0; }
  .service-pricing-table p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
    color: #626161; }
  .service-pricing-table span {
    color: #373737;
    font-weight: 500; }
  .service-pricing-table thead tr th {
    border: none; }
  .service-pricing-table tbody {
    border-top: none !important; }
    .service-pricing-table tbody tr th,
    .service-pricing-table tbody tr td {
      box-shadow: none;
      border-bottom-width: 0;
      min-width: 9.375rem; }
    .service-pricing-table tbody tr:nth-child(odd) {
      background: #edfafa; }
    .service-pricing-table tbody tr:nth-child(even) {
      background: #ffffff; }
  .service-pricing-table > :not(caption) > * > * {
    padding: 1rem 1rem 1rem 0.5rem; }
  .service-pricing-table td p {
    margin: 0; }
  @media (max-width: 991.98px) {
    .service-pricing-table td {
      vertical-align: middle; } }

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ff7a05; }

.service-price {
  margin: 0.5rem 0; }

.worker-title {
  font-weight: 500; }

.news-content-outer,
.news-card {
  background: #ffffff;
  border-radius: 0.5rem 0px 0px 0.5rem; }

.news-content {
  padding: 2rem 1.5rem 2rem 2rem; }
  .news-content p {
    margin-bottom: 2.5rem;
    overflow: hidden;
    -webkit-line-clamp: 4;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal; }
  @media (max-width: 991.98px) {
    .news-content {
      padding: 1rem; } }

.news-typography span {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #a8a5a5;
  margin-bottom: 1rem;
  display: block; }
  .news-typography span h4, .news-typography span .h4 {
    font-size: 1.5rem;
    line-height: 1.938rem;
    color: #373737;
    margin-bottom: 0.5rem; }
  .news-typography span p {
    margin-bottom: 1.5rem; }

.news-more-link {
  line-height: 1.25rem;
  font-weight: 500;
  color: #17b9bc;
  align-items: center;
  padding-bottom: 1rem; }
  .news-more-link svg {
    margin-left: 0.5rem; }
    .news-more-link svg path {
      fill: #17b9bc; }
  .news-more-link:hover svg path {
    fill: #0e0e0e; }

.news-cards-container {
  margin-top: 3.5rem; }

.news-card-inner {
  padding: 1rem 0.5rem 0 1rem; }
  .news-card-inner span {
    padding-bottom: 0.25rem;
    display: block; }
  .news-card-inner h3, .news-card-inner .h3 {
    margin-bottom: 0.5rem; }
  .news-card-inner p {
    margin: 0;
    padding-bottom: 1.5rem;
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal; }

.news-container {
  padding: 3.5rem 0 4rem; }
  .news-container .row .col-md-6 {
    margin-bottom: 1.5rem; }
  .news-container h1, .news-container .h1 {
    margin-bottom: 3.5rem; }
  @media (max-width: 991.98px) {
    .news-container {
      padding: 2.5rem 1.5rem 5.5rem;
      margin: 0;
      max-width: 100%; }
      .news-container h1, .news-container .h1 {
        margin-bottom: 2.5rem; } }

.news-list {
  padding: 0 0 3.5rem; }
  @media (max-width: 991.98px) {
    .news-list {
      max-width: 100%;
      margin: 0;
      padding: 0 1.5rem 3.5rem; } }

.hero-container {
  max-width: 120rem;
  padding: 0; }

#search-trip-block {
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 2rem 2rem 1.125rem;
  top: -5.75rem; }
  @media (max-width: 1199.98px) {
    #search-trip-block {
      top: -4rem; } }
  @media (max-width: 991.98px) {
    #search-trip-block {
      margin: 0;
      padding: 2rem 1.5rem 1.125rem; } }

.hero-img-container {
  position: relative;
  z-index: 0; }

.new-offers {
  padding: 0 0 3.5rem; }
  .new-offers h2, .new-offers .h2 {
    margin: 0 0 3.5rem; }
  @media (max-width: 991.98px) {
    .new-offers {
      padding: 0 1.5rem 3.5rem;
      margin: 0;
      max-width: 100%; }
      .new-offers h2, .new-offers .h2 {
        margin: 0 0 2rem; } }

.offer-card-img-block {
  background: #ffffff;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  height: 100%; }
  @media (max-width: 991.98px) {
    .offer-card-img-block {
      display: flex;
      flex-direction: column; } }

.card-img-block-inner {
  position: relative;
  z-index: 0; }

.offer-img-container {
  position: absolute;
  top: 1rem;
  z-index: 1;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  left: -0.5rem;
  width: 100%; }

.offer-content {
  padding: 1rem 1.5rem 1.5rem;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12); }
  .offer-content h4, .offer-content .h4 {
    margin: 0 0 0.5rem; }
  .offer-content a {
    color: #ff7a05;
    line-height: 1.25rem;
    font-weight: 500; }
  @media (max-width: 991.98px) {
    .offer-content {
      padding: 1rem; } }

.offer-content-inner {
  max-width: 34.063rem; }
  .offer-content-inner p {
    margin-bottom: 2rem;
    overflow: hidden;
    -webkit-line-clamp: 4;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal; }
  .offer-content-inner h4, .offer-content-inner .h4 {
    color: #373737; }

.full-height-offer-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 0.5rem 0px 0px 0.5rem; }
  @media (min-width: 1400px) {
    .full-height-offer-img {
      min-width: 18.875rem; } }
  @media (max-width: 991.98px) {
    .full-height-offer-img {
      border-radius: 0.5rem 0.5rem 0px 0px;
      max-height: 300px; } }

.special-container {
  padding: 5.5rem 0 4.375rem; }
  @media (max-width: 991.98px) {
    .special-container {
      margin: 0;
      max-width: 100%;
      padding: 3.5rem 1.5rem 2rem; } }

.special-typography h2, .special-typography .h2 {
  color: #ffffff; }
  @media (max-width: 991.98px) {
    .special-typography h2, .special-typography .h2 {
      margin-bottom: 1.5rem; } }

.special-typography .rest-trips-links {
  margin-bottom: 0.75rem; }

.saved-container {
  padding: 3.5rem 0 4rem; }
  .saved-container h1, .saved-container .h1 {
    margin-bottom: 3.5rem; }
  @media (max-width: 991.98px) {
    .saved-container {
      margin: 0;
      padding: 3.5rem 1.5rem 4rem;
      max-width: 100%; }
      .saved-container h1, .saved-container .h1 {
        margin-bottom: 2rem; } }

.trip-info-outer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem 1rem; }

.trip-info-icons {
  display: flex;
  align-items: center;
  margin: 0 0.5rem 0.5rem; }
  .trip-info-icons svg {
    margin-right: 0.375rem; }
  .trip-info-icons span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #a8a5a5;
    word-break: break-all; }

.price-label {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #17b9bc;
  margin-right: 0.5rem; }

.price {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.688rem;
  color: #17b9bc; }

.price-label,
.price {
  word-break: break-all; }

.spacer {
  padding-top: 5.5rem; }

.small-spacer {
  padding-top: 2.5rem; }

.medium-spacer {
  padding-top: 3.5rem; }

.links-page-container {
  padding: 3.5rem 0 5.5rem; }
  .links-page-container h1, .links-page-container .h1 {
    margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .links-page-container {
      margin: 0;
      padding: 3.5rem 1rem 2rem;
      max-width: 100%; } }

.links-table {
  margin: 3rem 0 0;
  border-collapse: collapse; }
  .links-table > :not(:first-child) {
    border-top: none; }
  .links-table h4, .links-table .h4 {
    color: #373737;
    margin: 0; }
  .links-table thead tr td {
    padding: 0 1rem 1.5rem 0; }
  .links-table thead tr td:not(:first-child) {
    padding: 0 1rem 1.5rem; }
  .links-table tbody tr td:not(:last-child) {
    border-right: 1px solid #ececec; }
  .links-table tbody td:not(:first-child) {
    padding: 1rem 1rem 1.5rem; }
  .links-table tbody td {
    padding: 1rem 1rem 1.5rem 0; }
    .links-table tbody td p {
      margin: 0;
      color: #626161; }
    .links-table tbody td a,
    .links-table tbody td p {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400; }
    .links-table tbody td a {
      color: #ff7a05;
      font-weight: 500; }
  .links-table td {
    border-color: #ececec;
    min-width: 19rem; }

.spacing-small {
  padding-bottom: 2.5rem; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff; }

.placeholder {
  background-color: #ffffff;
  cursor: pointer; }

.cc-revoke {
  display: none !important; }

.rest-trip {
  padding: 0 0 0.75rem;
  align-items: center; }
  .rest-trip h4, .rest-trip .h4 {
    color: #ffffff;
    padding: 2.5px 0; }
  .rest-trip svg {
    margin-right: 0.5rem; }

.cc-dismiss {
  display: none !important; }

.saved-trip-content {
  padding: 1rem 1rem 1.5rem;
  width: 100%;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12); }
  .saved-trip-content h4, .saved-trip-content .h4 {
    margin-top: 1rem; }

#offcanvasFilterMenu .offcanvas-body {
  margin-right: 1rem; }

#offcanvasFilterMenu a {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.search-container {
  padding: 0; }
  @media (max-width: 991.98px) {
    .search-container {
      margin: 0;
      max-width: 100%;
      padding: 1.5rem 1.5rem 0; } }

.search-collapse {
  padding: 0; }
  @media (min-width: 992px) {
    .search-collapse {
      display: flex; } }

.trip-link, .trip-link-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  color: #373737; }
  .trip-link svg, .trip-link-pagination svg {
    margin-left: 0.5rem; }

.trip-link-pagination svg {
  transform: rotate(90deg); }

@media (max-width: 991.98px) {
  .trip-link-pagination {
    padding: 0; } }

.rest-trips-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1.625rem; }
  .rest-trips-links a {
    display: block;
    color: #ffffff;
    padding: 0 0 0.75rem; }

.hero-banner-block {
  position: absolute;
  padding: 0;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 991.98px) {
    .hero-banner-block {
      justify-content: start;
      flex-direction: column;
      align-items: flex-start;
      top: 6rem;
      margin: 0;
      max-width: 100%;
      padding: 0 1.5rem; } }
  @media (max-width: 767.98px) {
    .hero-banner-block {
      max-width: 100%;
      padding: 0 1.5rem; } }

h1, .h1 {
  color: #ffffff; }

.featured-card {
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  max-width: 25.625rem; }
  @media (min-width: 992px) {
    .featured-card {
      margin-left: 0.75rem; } }
  .featured-card p {
    margin: 0; }
  .featured-card h3, .featured-card .h3 {
    color: #ffffff; }
  .featured-card .price {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.688rem; }
  .featured-card .price-label {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem; }
  .featured-card svg path {
    fill: #ffffff; }
  @media (max-width: 991.98px) {
    .featured-card {
      margin-top: 3rem; } }

.featured-card-outer {
  background: #17b9bc;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .featured-card-outer h3, .featured-card-outer .h3 {
    margin: 0; }
  .featured-card-outer span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #ffffff; }
  .featured-card-outer svg {
    margin-right: 0.5rem; }

.featured-card-inner {
  background: #ffffff;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1rem; }
  .featured-card-inner p {
    margin-bottom: 1rem;
    line-height: 1.5rem; }

.long-link {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #ff7a05;
  font-weight: 500; }
  .long-link svg {
    margin-left: 0.5rem; }
    .long-link svg path {
      fill: #ff7a05; }
  .long-link:hover svg path {
    fill: #0e0e0e; }

.hero-title-block {
  max-width: 37.375rem; }
  .hero-title-block h1, .hero-title-block .h1 {
    color: #ffffff; }
  @media (min-width: 992px) {
    .hero-title-block {
      margin-right: 0.75rem; } }

.lightbox-main-img {
  width: 100%;
  max-height: 462px;
  height: 100%;
  object-fit: cover;
  position: relative; }
  @media (max-width: 991.98px) {
    .lightbox-main-img {
      max-height: 18.875rem; } }

.lightbox-sub-img {
  width: 100%;
  object-fit: cover;
  height: 227px; }
  @media (max-width: 767.98px) {
    .lightbox-sub-img {
      height: 100%; } }

.banner-container {
  padding: 0; }
  @media (max-width: 991.98px) {
    .banner-container {
      max-width: 100%;
      margin: 0;
      padding: 0 1.5rem; } }

.offer-banner-container {
  padding: 0 0 3rem; }
  @media (max-width: 991.98px) {
    .offer-banner-container {
      padding: 0 1.5rem 3rem;
      max-width: 100%;
      margin: 0; } }

.saved-plan-link.active svg path {
  fill: #ff7a05; }

#languageCollapse img {
  margin: 0.5rem 0.5rem 0 0; }

.full-width-image {
  width: 100%;
  object-fit: cover; }

.inner-title {
  margin-bottom: 1.5rem;
  text-align: center; }
  @media (min-width: 992px) and (max-width: 1399.98px) {
    .inner-title {
      font-size: 1.75rem !important; } }
  @media (max-width: 767.98px) {
    .inner-title {
      font-size: 1.25rem !important;
      line-height: 2rem; } }

.select2-container--default .select2-results > .select2-results__options {
  overflow-y: scroll !important;
  scrollbar-color: #626161;
  scrollbar-width: thin; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
    width: 0.4rem;
    position: absolute;
    top: 0; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track {
    border-radius: 0.875rem; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
    background-color: #626161;
    border-radius: 0.875rem; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb:hover {
    background: #626161; }

.contact-map {
  width: 30.938rem; }
  @media (max-width: 991.98px) {
    .contact-map {
      width: 100%;
      height: 16.875rem; } }

.contacts-card {
  box-shadow: 0px 0.25rem 0.25rem rgba(216, 216, 216, 0.12);
  border-radius: 0.5rem 0px 0px 0.5rem;
  background: #ffffff;
  padding: 1.5rem;
  max-width: 25.75rem;
  width: 100%;
  height: 100%; }
  @media (max-width: 991.98px) {
    .contacts-card {
      max-width: 100%;
      border-radius: 0px 0px 0.5rem 0.5rem; } }
  .contacts-card h4, .contacts-card .h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    margin-bottom: 1.5rem;
    color: #373737; }
  .contacts-card span,
  .contacts-card a {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.125rem; }
  .contacts-card span:not(:last-child),
  .contacts-card a {
    margin-bottom: 0.75rem; }
  .contacts-card .work-time-col span {
    margin-bottom: 0.2rem; }
  .contacts-card svg {
    margin-right: 0.5rem; }
    .contacts-card svg path {
      fill: #17b9bc; }
  .contacts-card .work-hours-image {
    align-self: baseline; }

#contacts-pills {
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden; }
  #contacts-pills .nav-item .nav-link {
    border-bottom: 3px solid #ececec;
    margin: 0 0 0.5rem;
    border-radius: 2px;
    color: #626161; }
  #contacts-pills .nav-item .nav-link.active {
    font-weight: 500;
    border-bottom: 3px solid #17b9bc;
    color: #17b9bc;
    background: unset; }
  #contacts-pills li {
    display: inline-block; }

#contacts-pills + .tab-content {
  margin-bottom: 1.5rem; }

#contacts-pills {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */ }

#contacts-pills::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0; }

.contacts-inner-content {
  max-width: 56.5rem; }

.location-info {
  margin: 2.5rem 0 1.5rem; }
  @media (max-width: 991.98px) {
    .location-info {
      flex-direction: column-reverse; } }

.address-info {
  margin: 1.5rem 0 3rem; }
  @media (max-width: 991.98px) {
    .address-info {
      flex-direction: column-reverse;
      margin: 1.5rem 0; } }

.half-width-inputs {
  display: flex;
  width: 100%; }
  @media (max-width: 991.98px) {
    .half-width-inputs {
      flex-direction: column; } }
  .half-width-inputs input {
    width: 50%;
    margin-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .half-width-inputs {
      flex-direction: column; }
      .half-width-inputs input {
        width: 100%; } }
  .half-width-inputs .form-control:disabled,
  .half-width-inputs .form-control[readonly] {
    background-color: #ffffff; }

.contact-card-heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.938rem;
  margin-bottom: 1.5rem;
  color: #373737; }

.contact-card-sub {
  border-radius: 0.5rem;
  max-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.contact-sub-col {
  padding-bottom: 3rem;
  margin-bottom: 3rem; }
  @media (max-width: 767.98px) {
    .contact-sub-col {
      padding-bottom: 1.5rem; } }

.contacts-card .inquiry-form-header-text {
  font-size: 1.125rem;
  line-height: 130%;
  color: #626161; }

#enquiryForm input, #enquiryForm textarea, #infoForm input, #infoForm textarea, #purchaseForm input, #purchaseForm textarea {
  width: 100%;
  border-color: #ececec; }

#enquiryForm .input-group, #infoForm .input-group, #purchaseForm .input-group {
  flex-wrap: nowrap; }

#enquiryForm .spinners, #infoForm .spinners, #purchaseForm .spinners {
  margin-bottom: 1rem; }
  @media (max-width: 336px) {
    #enquiryForm .spinners, #infoForm .spinners, #purchaseForm .spinners {
      flex-wrap: wrap; } }
  #enquiryForm .spinners .form-label, #infoForm .spinners .form-label, #purchaseForm .spinners .form-label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem; }
  #enquiryForm .spinners .input-group, #infoForm .spinners .input-group, #purchaseForm .spinners .input-group {
    flex-wrap: nowrap; }
    #enquiryForm .spinners .input-group input, #infoForm .spinners .input-group input, #purchaseForm .spinners .input-group input {
      padding: 0.5rem; }

@media (max-width: 767.98px) {
  #enquiryForm input,
  #enquiryForm .input-group, #infoForm input,
  #infoForm .input-group, #purchaseForm input,
  #purchaseForm .input-group {
    max-width: 100%; } }

#enquiryForm .input-group-text, #infoForm .input-group-text, #purchaseForm .input-group-text {
  background: #ffffff;
  border-right: 0;
  border-color: #ececec;
  margin: 0; }

#enquiryForm .form-control, #infoForm .form-control, #purchaseForm .form-control {
  padding: 1rem; }

#enquiryForm .flatpickr-calendar.arrowTop:before, #enquiryForm .flatpickr-calendar.arrowTop:after {
  display: none; }

#enquiryForm .spinners .form-label {
  margin-bottom: 0.75rem; }

#enquiryForm .spinners .input-group {
  max-width: 126px; }

#enquiryForm .form-control {
  margin-bottom: 1rem; }

#enquiryForm .flatpickr-input {
  padding-left: 2.75rem; }

.modal-title {
  color: #ff7a05; }

.modal-header {
  border: none;
  padding: 2rem; }

.modal-body {
  padding: 0 2rem 2rem; }

@media (max-width: 991.98px) {
  .modal-body {
    padding: 0 1.5rem 2rem; }
  .modal-header {
    padding: 1.5rem; } }

.select2-selection {
  text-align: left; }

.select2-selection__rendered,
.select2-selection__arrow {
  margin: 1rem; }

.select2-container .select2-selection--single {
  height: 3.5rem; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  align-items: center; }
  .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
    justify-content: center; }

#contactModal .modal-header {
  padding: 2rem 2rem 0;
  align-items: baseline; }

#contactModal h3, #contactModal .h3 {
  margin-bottom: 1.5rem; }

.input-img {
  position: relative; }
  .input-img .input-svg {
    position: absolute;
    top: 1rem;
    left: 1rem; }

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #ff7a05 !important; }

.daterangepicker td.off.ends.active {
  color: #ffffff !important; }

@media (max-width: 575.98px) {
  .daterangepicker .drp-selected {
    padding-bottom: 0.5rem;
    display: block !important; } }

.applyBtn {
  background-color: #ff7a05 !important;
  color: #ffffff !important;
  border: none; }

.modal-backdrop {
  display: none !important; }

@media (min-width: 992px) {
  .content-404 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

@media (max-width: 991.98px) {
  .container-404 {
    padding: 5.5rem 1.5rem 8rem; } }

#jobAccordion button:focus:not(:focus-visible) {
  background: inherit;
  color: inherit; }

#jobAccordion li {
  font-size: 0.875rem; }

#jobAccordion .accordion-item {
  border: 0;
  margin-bottom: 0.75rem; }

#jobAccordion .accordion-collapse {
  margin-top: 0.5rem; }

#jobAccordion .accordion-spacer {
  height: 0.5rem;
  width: 100%;
  background: #fbfbfb; }

#jobAccordion .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: #ffffff; }
  #jobAccordion .accordion-button:not(.collapsed) span {
    color: #626161; }

#jobAccordion .accordion-button {
  border-radius: 0.25rem;
  padding: 2rem 1rem 0;
  align-items: baseline; }
  #jobAccordion .accordion-button span {
    line-height: 1.25rem;
    margin-right: 0.5rem;
    font-weight: 500;
    font-family: "DM Sans", "sans-serif"; }
  #jobAccordion .accordion-button img {
    transition: all 0.2s; }

#jobAccordion .accordion-button[aria-expanded="true"] img {
  transform: rotate(180deg); }

#jobAccordion .accordion-button::after {
  display: none; }

#jobAccordion .accordion-body {
  padding: 1.5rem; }
  #jobAccordion .accordion-body ul {
    margin-bottom: 0; }
  #jobAccordion .accordion-body .row {
    margin: 0; }
  @media (max-width: 991.98px) {
    #jobAccordion .accordion-body {
      padding: 1.5rem 1rem; } }
  #jobAccordion .accordion-body .col-lg-6 {
    padding: 0 0.5rem; }
    #jobAccordion .accordion-body .col-lg-6:not(:last-child) {
      margin-bottom: 1.5rem; }
    @media (max-width: 991.98px) {
      #jobAccordion .accordion-body .col-lg-6 {
        padding: 0; } }
  #jobAccordion .accordion-body p {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #373737; }

.career-container {
  padding: 3.5rem 0 16rem; }
  @media (max-width: 991.98px) {
    .career-container {
      padding: 3.5rem 1.5rem 10rem;
      margin: 0;
      max-width: 100%; } }
  .career-container h1, .career-container .h1 {
    margin-bottom: 3.5rem; }

.accordion-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap; }
  .accordion-title h4, .accordion-title .h4 {
    margin: 0;
    padding-bottom: 1.5rem; }
  .accordion-title span {
    padding-bottom: 2rem; }

.career-inner-content {
  max-width: 52.813rem; }
  .career-inner-content h3, .career-inner-content .h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    color: #17b9bc;
    margin-bottom: 1.5rem; }
  .career-inner-content p {
    margin-bottom: 2.5rem; }

.job-offer-notes {
  padding: 0;
  max-width: 25.75rem; }
  .job-offer-notes span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem; }
  .job-offer-notes a {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #17b9bc; }

.filter-box {
  background: #ffffff;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem 0px 0px 0.5rem;
  padding: 2.5rem 2.5rem 0.875rem; }
  @media (max-width: 991.98px) {
    .filter-box {
      padding: 0; } }
  @media (min-width: 992px) {
    .filter-box .offcanvas-header {
      display: none; } }
  .filter-box span {
    color: #373737; }
  @media (min-width: 1860px) {
    .filter-box .offcanvas-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    .filter-box .filter-box-container {
      min-width: 8.75rem; } }
  .filter-box h4, .filter-box .h4 {
    margin-bottom: 1.25rem;
    color: #373737; }

.filter-link-container {
  margin-bottom: 0.375rem; }
  .filter-link-container a {
    color: #373737;
    display: block;
    padding-bottom: 0.75rem; }
  .filter-link-container span {
    color: #a8a5a5;
    margin-left: 0.25rem; }

.filter-box-mobile,
.filter-box-single {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between; }
  .filter-box-mobile span,
  .filter-box-single span {
    display: flex;
    align-items: center; }
  .filter-box-mobile a span,
  .filter-box-single a span {
    margin-left: 0.375rem; }

@media (min-width: 992px) {
  .filter-box-mobile {
    display: none; } }

.filter-box-single select {
  max-width: 200px; }

@media (min-width: 992px) {
  .filter-box-single {
    justify-content: flex-end; } }

@media (min-width: 992px) {
  .filter-box-single span {
    display: none; } }

.form-select {
  color: #373737; }

#filterCollapseLink {
  display: block; }
  #filterCollapseLink span {
    margin: 0; }

#filterCollapseLink[aria-expanded="true"] img {
  transform: rotate(180deg); }

#filterCollapse {
  margin-top: 1.25rem; }

.gift-collapse {
  margin-top: 4.75rem; }

.gift-collapse-link {
  display: flex;
  align-items: center; }
  .gift-collapse-link svg {
    margin: 0; }
    .gift-collapse-link svg path {
      fill: #373737; }

.gift-collapse-link[aria-expanded="true"] svg {
  transform: rotate(180deg); }

.conditions-inner-content h3, .conditions-inner-content .h3 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.938rem;
  color: #17b9bc;
  margin-bottom: 1rem; }

.conditions-inner-content p {
  margin-bottom: 1.5rem; }

.conditions-inner-content h4, .conditions-inner-content .h4 {
  margin-bottom: 2rem; }

.conditions-list-table {
  margin-bottom: 2rem; }

.condition-page-list li {
  padding-left: 0.5em; }

.condition-page-list span {
  color: #373737;
  font-weight: 500; }

.condition-page-list a {
  color: #ff7a05;
  font-weight: 500; }

.conditions-container li::marker {
  content: counters(list-item, ".") "."; }

.conditions-container {
  padding: 3.5rem 0 4rem; }
  .conditions-container .row .col-md-6 {
    margin-bottom: 1.5rem; }
  .conditions-container h1, .conditions-container .h1 {
    margin-bottom: 3.5rem; }
  @media (max-width: 991.98px) {
    .conditions-container {
      padding: 3.5rem 1.5rem 5.5rem;
      margin: 0;
      max-width: 100%; }
      .conditions-container h1, .conditions-container .h1 {
        margin-bottom: 2.5rem; } }
  .conditions-container a {
    color: #ff7a05;
    font-weight: 500; }

#c-ttl,
#s-ttl {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.125rem !important;
  line-height: 1.438rem;
  color: #373737; }

#c-txt,
#c-bl {
  font-family: DM Sans, sans-serif;
  size: 1rem;
  color: #626161;
  line-height: 1.5rem; }

.cc_div .cc-link {
  border-bottom: 1px solid #373737;
  color: #373737 !important; }

#c-bns button:first-child,
#s-bns button:first-child {
  background: #ff7a05 !important;
  font-size: 1rem; }

#s-rall-bn,
#c-s-bn {
  background: #17b9bc;
  font-size: 1rem;
  color: #ffffff; }
  #s-rall-bn:hover,
  #c-s-bn:hover {
    background: #17b9bc !important; }

#s-sv-bn {
  background: #373737;
  font-size: 1rem;
  color: #ffffff; }
  #s-sv-bn:hover {
    background: #373737 !important; }

#s-c-bn {
  background: #edfafa; }
  #s-c-bn::after, #s-c-bn::before {
    background: #373737 !important; }

.b-tl {
  color: #373737 !important; }

#s-cnt .p {
  color: #626161 !important; }

.c-tgl:checked ~ .c-tg,
.c-tg.c-ro {
  background: #ff7a05 !important; }

.c-tg.c-ro {
  opacity: 0.5; }

.c-tgl:not(:checked) ~ .c-tg {
  background: #a8a5a5; }

@media screen and (max-width: 688px) {
  .bns-t #s-bns {
    height: 11.5em !important; } }

.iti {
  position: relative;
  display: inline-block; }

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.iti__hide {
  display: none; }

.iti__v-hide {
  visibility: hidden; }

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0; }

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px; }

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px; }

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555; }

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555; }

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px; }

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal; } }

.iti__flag-box {
  display: inline-block;
  width: 20px; }

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC; }

.iti__country {
  padding: 5px 10px;
  outline: none; }

.iti__dial-code {
  color: #999; }

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05); }

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle; }

.iti__flag-box, .iti__country-name {
  margin-right: 6px; }

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0; }

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0; }

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer; }

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default; }

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent; }

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05); }

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px; }

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px; }

.iti--container:hover {
  cursor: pointer; }

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%; }

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em; }

.iti__flag {
  width: 20px; }

.iti__flag.iti__be {
  width: 18px; }

.iti__flag.iti__ch {
  width: 15px; }

.iti__flag.iti__mc {
  width: 19px; }

.iti__flag.iti__ne {
  width: 18px; }

.iti__flag.iti__np {
  width: 13px; }

.iti__flag.iti__va {
  width: 15px; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px; } }

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px; }

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px; }

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px; }

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px; }

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px; }

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px; }

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px; }

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px; }

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px; }

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px; }

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px; }

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px; }

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px; }

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px; }

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px; }

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px; }

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px; }

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px; }

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px; }

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px; }

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px; }

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px; }

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px; }

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px; }

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px; }

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px; }

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px; }

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px; }

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px; }

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px; }

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px; }

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px; }

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px; }

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px; }

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px; }

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px; }

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px; }

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px; }

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px; }

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px; }

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px; }

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px; }

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px; }

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px; }

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px; }

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px; }

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px; }

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px; }

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px; }

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px; }

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px; }

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px; }

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px; }

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px; }

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px; }

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px; }

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px; }

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px; }

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px; }

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px; }

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px; }

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px; }

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px; }

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px; }

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px; }

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px; }

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px; }

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px; }

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px; }

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px; }

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px; }

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px; }

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px; }

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px; }

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px; }

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px; }

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px; }

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px; }

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px; }

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px; }

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px; }

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px; }

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px; }

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px; }

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px; }

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px; }

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px; }

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px; }

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px; }

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px; }

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px; }

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px; }

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px; }

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px; }

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px; }

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px; }

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px; }

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px; }

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px; }

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px; }

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px; }

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px; }

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px; }

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px; }

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px; }

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px; }

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px; }

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px; }

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px; }

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px; }

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px; }

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px; }

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px; }

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px; }

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px; }

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px; }

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px; }

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px; }

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px; }

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px; }

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px; }

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px; }

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px; }

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px; }

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px; }

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px; }

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px; }

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px; }

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px; }

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px; }

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px; }

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px; }

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px; }

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px; }

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px; }

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px; }

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px; }

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px; }

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px; }

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px; }

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px; }

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px; }

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px; }

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px; }

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px; }

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px; }

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px; }

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px; }

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px; }

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px; }

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px; }

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px; }

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px; }

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px; }

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px; }

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px; }

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px; }

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px; }

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px; }

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px; }

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px; }

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px; }

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px; }

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px; }

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px; }

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px; }

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px; }

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px; }

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px; }

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px; }

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px; }

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px; }

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px; }

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px; }

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px; }

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px; }

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px; }

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px; }

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px; }

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px; }

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px; }

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px; }

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px; }

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px; }

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px; }

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px; }

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px; }

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px; }

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px; }

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px; }

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px; }

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px; }

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px; }

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px; }

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px; }

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px; }

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px; }

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px; }

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px; }

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px; }

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px; }

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px; }

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px; }

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px; }

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px; }

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px; }

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px; }

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px; }

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px; }

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px; }

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px; }

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px; }

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px; }

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px; }

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px; }

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px; }

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px; }

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px; }

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px; }

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px; }

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px; }

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px; }

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px; }

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px; }

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px; }

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px; }

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px; }

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px; }

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px; }

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px; }

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px; }

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px; }

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px; }

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px; }

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px; }

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px; }

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px; }

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px; }

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px; }

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px; }

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px; }

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px; }

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px; }

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px; }

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px; }

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px; }

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px; }

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px; }

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px; }

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px; }

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px; }

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px; }

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px; }

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px; }

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px; }

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px; }

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px; }

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px; }

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags@2x.png"); } }

.iti__flag.iti__np {
  background-color: transparent; }

.checkout-container,
.gift-checkout-container {
  padding: 3.5rem 0 5rem; }
  @media (max-width: 991.98px) {
    .checkout-container,
    .gift-checkout-container {
      max-width: 100%;
      padding: 3.5rem 1.5rem 5rem; } }
  .checkout-container span,
  .gift-checkout-container span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #a8a5a5; }

@media (max-width: 991.98px) {
  .gift-checkout-container {
    padding: 3.5rem 0 5rem; } }

.checkout-summary-container {
  padding: 3.5rem 0 5rem; }
  @media (max-width: 991.98px) {
    .checkout-summary-container {
      max-width: 100%; }
      .checkout-summary-container .checkout-additional-col:first-child {
        padding-left: 0; }
      .checkout-summary-container .checkout-additional-col:nth-child(2) {
        padding-right: 0; } }

@media (max-width: 767.98px) {
  .checkout-additional-col {
    padding: 0; } }

.checkout-col {
  border-radius: 0.5rem; }
  @media (max-width: 991.98px) {
    .checkout-col {
      padding: 0;
      background: #ffffff;
      margin-bottom: 1.5rem; } }

.checkout-row {
  margin: 0; }
  @media (max-width: 991.98px) {
    .checkout-row {
      margin: 0 -1.5rem; } }

.checkout-content {
  padding: 2rem;
  border-radius: 0.5rem;
  background: #ffffff;
  border-radius: 0.5rem; }
  .checkout-content h3, .checkout-content .h3 {
    margin-bottom: 1.5rem; }
  @media (max-width: 991.98px) {
    .checkout-content {
      padding: 2rem 1.5rem; } }

.full-height-checkout-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 0.5rem; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .full-height-checkout-img {
      width: 50%; } }

.checkout-img-banner {
  padding: 0.5rem;
  background: #edfafa;
  border-radius: 0.25rem; }
  .checkout-img-banner p {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: #17b9bc; }

.checkout-info-outer {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1.5rem; }
  .checkout-info-outer p {
    max-width: 504px; }
  .checkout-info-outer h4, .checkout-info-outer .h4 {
    margin-bottom: 1.5rem; }
  @media (max-width: 991.98px) {
    .checkout-info-outer {
      margin: 1.5rem 0 0.5rem; } }

.checkout-info-icons {
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem; }
  .checkout-info-icons svg {
    margin-right: 0.375rem; }
  .checkout-info-icons span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #626161; }

.checkout-table {
  margin: 1.5rem 0 0;
  border-spacing: 0; }
  .checkout-table .mobile-checkout-info {
    margin: 0; }
  @media (min-width: 992px) {
    .checkout-table .mobile-checkout-info {
      display: none; } }
  @media (max-width: 991.98px) {
    .checkout-table {
      margin: 1.5rem 0 0; } }
  @media (max-width: 575.98px) {
    .checkout-table thead,
    .checkout-table .table-border {
      display: none; }
    .checkout-table tr {
      display: flex;
      flex-direction: column;
      border: 1px solid #ececec; }
      .checkout-table tr:first-child {
        margin-bottom: 2rem; }
      .checkout-table tr td {
        display: flex;
        justify-content: space-between;
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .checkout-table > :not(caption) > * > * {
      padding: 0.875rem 0; } }
  .checkout-table > :not(:first-child) {
    border-top: none; }
  @media (min-width: 576px) {
    .checkout-table > :not(caption) > * > * {
      border-bottom-width: 0;
      padding: 0.875rem 1.5rem; } }
  .checkout-table thead tr p {
    font-weight: 500;
    line-height: 1.125rem;
    color: #ffffff;
    margin: 0; }
  .checkout-table thead {
    background: #17b9bc; }
  .checkout-table td {
    position: relative; }
    @media (max-width: 1199.98px) {
      .checkout-table td {
        min-width: 15.125rem; } }
  .checkout-table td:not(:first-child) {
    text-align: center; }
  .checkout-table .form-check {
    display: flex;
    align-items: end; }
    .checkout-table .form-check input {
      margin-right: 0.5rem; }
  .checkout-table .form-check-input:checked {
    background-color: #ffffff;
    border-color: #17b9bc; }
  .checkout-table .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    border-color: #a8a5a5;
    border-width: 2px; }
  .checkout-table .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2317B9BC'/%3e%3c/svg%3e"); }
  .checkout-table span,
  .checkout-table .form-check-label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #626161; }

.table-wrapper {
  box-shadow: 0px 0.25rem 0.25rem rgba(216, 216, 216, 0.12); }

.table-border {
  border: 1px solid #ececec;
  transform: rotate(90deg);
  width: 1.5rem;
  right: 0;
  top: 1.5rem;
  position: absolute; }

.optional-checkout-fields {
  max-width: 26.875rem;
  margin-top: 2rem; }
  .optional-checkout-fields label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #626161;
    margin-bottom: 0.25rem; }
  .optional-checkout-fields input {
    margin-bottom: 1rem;
    padding: 0.75rem 1.5rem;
    border: 1px solid #ececec;
    border-radius: 0.25rem; }

.terms-accept-block {
  max-width: 26.875rem; }
  .terms-accept-block .form-check-input {
    border-radius: 1px; }
  .terms-accept-block .form-check-input:checked {
    background-color: #ff7a05;
    border-color: #a8a5a5; }

.remaining-label {
  margin-bottom: 1.25rem;
  display: block;
  margin-top: 1rem; }

.optional-user-fields label,
.checkout-user-fields label {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #626161;
  margin-bottom: 0.25rem; }

.optional-user-fields input,
.checkout-user-fields input {
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border: 1px solid #ececec;
  border-radius: 0.25rem; }

.checkout-confirm-container {
  padding: 2.5rem 0 11.25rem; }
  @media (max-width: 991.98px) {
    .checkout-confirm-container {
      padding: 5.5rem 1.5rem 8.125rem;
      margin: 0;
      max-width: 100%; } }

#passengersAccordion .accordion-header {
  margin-bottom: 1rem; }

#passengersAccordion .accordion-body {
  padding-left: 0;
  padding-right: 0; }

#passengersAccordion p {
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem; }

#passengersAccordion .accordion-button {
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  background: #edfafa;
  border-radius: 0.25rem; }

#passengersAccordion .accordion-button::after {
  background-image: url("../images/accordion_icon.svg");
  width: 1.5rem;
  height: 1.5rem; }

#passengersAccordion .accordion-item {
  margin-bottom: 1rem;
  border: none; }

#passengersAccordion .input-group {
  max-width: 126px; }

.passengers-block {
  margin-top: 3rem; }

.passenger-number-input + .input-group {
  min-width: 7.875rem; }

.btn-minus,
.btn-plus {
  background: #ececec;
  border-radius: 0.25rem;
  color: #626161;
  border-color: transparent; }

.summary-secondary-info {
  max-width: 46.25rem; }
  @media (max-width: 991.98px) {
    .summary-secondary-info {
      margin: 0 1.5rem; } }

.checkout-summary-content-inner {
  max-width: 34.063rem; }
  .checkout-summary-content-inner p {
    margin-bottom: 2rem;
    font-size: 0.875rem; }
  .checkout-summary-content-inner h4, .checkout-summary-content-inner .h4 {
    color: #373737; }

.checkout-service-card {
  background: #ffffff;
  box-shadow: 0px 0.25rem 0.25rem rgba(216, 216, 216, 0.12);
  border-radius: 0.5rem;
  border: none;
  transition: all 0.3s;
  max-width: 14.375rem; }

.checkout-services-cards {
  margin: 0 -0.75rem; }
  .checkout-services-cards .checkout-service-card {
    margin: 0 0 1.5rem; }
  .checkout-services-cards .checkout-service-card-outer {
    margin: 0 0.75rem; }

.checkout-col-card {
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem; }
  @media (max-width: 991.98px) {
    .checkout-col-card {
      padding: 2rem 1.5rem; } }
  .checkout-col-card h3, .checkout-col-card .h3 {
    color: #373737;
    margin-bottom: 1.5rem; }
  .checkout-col-card span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #a8a5a5;
    font-weight: 400; }
  .checkout-col-card .checkout-price {
    color: #373737; }
  .checkout-col-card .full-checkout-price {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.688rem;
    font-family: "Montserrat";
    color: #373737; }

[name="paymentType"]:checked + img, [name="couponType"]:checked + div {
  outline: 2px solid #ff7a05; }

[name="paymentType"], [name="couponType"] {
  opacity: 0;
  position: absolute; }

.payment-type-blocks {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  border: 1px solid #ececec;
  padding-top: 1.5rem; }
  .payment-type-blocks label {
    padding-bottom: 2rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .payment-type-blocks label {
        padding-bottom: 1.25rem; } }

.coupons-type-blocks {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 575.98px) {
    .coupons-type-blocks .col-6 {
      padding-left: 0; } }

.coupons-type-blocks + button {
  margin-left: 0.75rem; }
  @media (max-width: 575.98px) {
    .coupons-type-blocks + button {
      margin-left: 0;
      width: 100%; } }

.gift-price {
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  justify-content: center; }

.payment-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
  border-bottom: 1px solid #ececec; }
  .payment-line p {
    margin-bottom: 0.75rem; }

.checkout-offer-content {
  padding: 1.5rem;
  width: 100%;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12); }
  .checkout-offer-content h4, .checkout-offer-content .h4 {
    margin: 0 0 0.5rem; }
  .checkout-offer-content a {
    color: #ff7a05;
    line-height: 1.25rem;
    font-weight: 500; }
  @media (max-width: 991.98px) {
    .checkout-offer-content {
      padding: 1rem; } }

.checkout-service-card h3, .checkout-service-card .h3 {
  color: #ff7a05; }

.checkout-service-card {
  max-width: unset;
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .checkout-service-card p {
    padding: 0 1rem;
    text-align: center; }

.service-enable-btn {
  display: inline-block;
  width: unset;
  padding: 0.875rem 2.5rem;
  margin-bottom: 1rem !important; }

.gifts-card-container {
  padding: 3.5rem 0 8rem; }
  @media (min-width: 992px) {
    .gifts-card-container .row .col-lg-6:nth-child(odd) {
      padding-left: 0; } }
  @media (max-width: 991.98px) {
    .gifts-card-container {
      margin: 0;
      max-width: 100%;
      padding: 3.5rem 1.5rem 6rem; } }
  .gifts-card-container h3, .gifts-card-container .h3 {
    margin: 2rem 0 1.5rem; }
  .gifts-card-container h1, .gifts-card-container .h1 {
    margin-bottom: 1.5rem; }
  .gifts-card-container ul li:not(:last-child) {
    padding-bottom: 0.75rem; }

.terms-link-text {
  color: #373737;
  margin: 1rem 0 2rem; }

.terms-link {
  color: #17b9bc; }

.gift-card {
  background: #ffffff;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.5rem 1rem;
  align-items: center; }
  .gift-card:not(:last-child) {
    margin-bottom: 1.5rem; }

.gift-price {
  display: flex;
  align-items: center; }
  .gift-price p,
  .gift-price h2,
  .gift-price .h2 {
    font-size: 1.8rem;
    color: #17b9bc;
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    
    .gift-price h2,
    .gift-price .h2 {
      font-size: 1.5rem; } }
  .gift-price p {
    margin-right: 1rem; }

.btn-decrement,
.btn-increment {
  color: #a8a5a5;
  font-weight: 400; }
  .btn-decrement:active, .btn-decrement:hover,
  .btn-increment:active,
  .btn-increment:hover {
    background-color: #373737; }

.gift-buy-block {
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .gift-buy-block {
      justify-content: space-between;
      width: 100%; } }
  .gift-buy-block svg {
    margin-left: 0.5rem; }
  .gift-buy-block svg path {
    fill: #ff7a05; }
  .gift-buy-block .quantity-block {
    margin-right: 1rem;
    max-width: 126px; }
    .gift-buy-block .quantity-block button {
      background: white;
      border: 1px solid #ced4da; }
      .gift-buy-block .quantity-block button:active, .gift-buy-block .quantity-block button:hover {
        background-color: #373737; }
    @media (min-width: 576px) and (max-width: 991.98px) {
      .gift-buy-block .quantity-block {
        max-width: 100%; } }

.gift-info-col {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem; }
  @media (max-width: 991.98px) {
    .gift-info-col {
      margin-bottom: 1.5rem;
      padding: 2.5rem 1.5rem; } }

.gift-info-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0 1.5rem;
  background: #edfafa;
  align-items: center;
  border-radius: 0.25rem; }
  .gift-info-container h2, .gift-info-container .h2,
  .gift-info-container p {
    color: #17b9bc;
    margin: 0; }

.identity-pills .nav-link.active {
  background-color: #ffffff;
  border-radius: 0.25rem; }

.identity-pills {
  border-radius: 0.25rem;
  background: #ececec;
  padding: 0.25rem;
  margin-bottom: 1.5rem; }
  .identity-pills .nav-item {
    width: 50%; }
    .identity-pills .nav-item button {
      margin: 0;
      width: 100%; }
      .identity-pills .nav-item button span {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem; }
  .identity-pills .nav-link.active span {
    color: #17b9bc; }

.dropdown-toggle::after {
  filter: unset; }

#searchForm .select2 {
  width: 100% !important; }

@media (max-width: 991.98px) {
  #searchForm .row .col-lg-3,
  #searchForm .row .col-lg-4 {
    margin-bottom: 1rem; } }

#searchForm .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  line-height: unset;
  font-size: 1rem;
  color: #626161; }

#searchForm .select2-container .select2-selection--single,
#searchForm .flatpickr-input {
  margin-top: -2px; }

#searchForm .select2-container .select2-selection--single {
  border: 1px solid #ececec;
  border-radius: 0px; }

#searchForm .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("../images/dropdown_arrow.svg");
  background-color: transparent;
  background-size: contain;
  border: none !important;
  height: 1rem !important;
  width: 1rem !important;
  margin: auto !important;
  top: auto !important;
  left: auto !important; }

#searchForm .select2-selection__placeholder {
  color: #626161 !important; }

#searchForm .form-control {
  padding: 1.125rem 1rem;
  line-height: 1.125rem;
  font-size: 1rem;
  border: 1px solid #ececec;
  border-radius: 0px;
  color: #626161; }

#searchForm .form-control:focus {
  box-shadow: none; }

#searchForm label {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #0e0e0e;
  margin-bottom: 0.25rem; }

#priceSlider,
#daySlider {
  margin: 1.5rem 0;
  background: #ffffff;
  border: none;
  box-shadow: none; }
  @media (max-width: 991.98px) {
    #priceSlider,
    #daySlider {
      margin: 1.5rem 0 0; } }
  #priceSlider .noUi-connects,
  #daySlider .noUi-connects {
    background: #ececec !important;
    height: 2px; }
  #priceSlider .noUi-connect,
  #daySlider .noUi-connect {
    background: #ff7a05 !important;
    height: 2px !important; }
  #priceSlider .noUi-handle-upper,
  #daySlider .noUi-handle-upper {
    right: -0.5rem; }
  #priceSlider .noUi-handle,
  #daySlider .noUi-handle {
    top: -0.75rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50%;
    border: 2px solid #ff7a05; }
    #priceSlider .noUi-handle::before, #priceSlider .noUi-handle::after,
    #daySlider .noUi-handle::before,
    #daySlider .noUi-handle::after {
      display: none; }

.search-dropdown button {
  text-align: left;
  color: #626161; }

.travelers-dropdown-list.show {
  display: flex;
  margin: 0 -0.75rem; }
  .travelers-dropdown-list.show li {
    margin: 0 0.75rem; }

.travelers-dropdown-list .input-group input {
  padding: 0 1rem !important; }

#searchCollapseLink[aria-expanded="true"] svg, #hotelInfoCollapseLink[aria-expanded="true"] svg {
  transform: rotate(180deg); }

#searchCollapseLink svg path, #hotelInfoCollapseLink svg path {
  fill: #626161; }

@media (min-width: 576px) {
  #searchCollapseLink {
    margin-left: 1.5rem; } }

.select2-dropdown {
  box-shadow: 0px 0px 16px 1px rgba(162, 162, 162, 0.12);
  border-radius: 4px;
  border: none; }

.select2-container--default .select2-results__option--selected {
  background-color: #fff4ea; }

.collapse-container {
  padding: 0; }

#purchaseForm .select2-container {
  width: 100% !important; }

#purchaseForm .select2-selection__rendered,
#purchaseForm .select2-selection__arrow {
  margin: 0; }

#purchaseForm .select2-container--default .select2-selection--multiple {
  padding: 1rem;
  border: 1px solid #ececec; }

#purchaseForm .select2-search__field {
  margin: 0;
  font-size: 1rem;
  line-height: 1.125rem; }

#purchaseForm .select2-selection__choice {
  background-color: #fff4ea;
  border: 1px solid #ececec; }

.trips-container {
  padding: 3.5rem 0 4rem; }
  .trips-container h1, .trips-container .h1,
  .trips-container h2,
  .trips-container .h2 {
    margin-bottom: 2.5rem; }
  @media (max-width: 991.98px) {
    .trips-container {
      margin: 0;
      padding: 2rem 1.5rem 4rem;
      max-width: 100%; }
      .trips-container h1, .trips-container .h1 {
        margin-bottom: 2rem; } }

.single-trip-card {
  background: #ffffff;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem 0.5rem 0px 0px; }
  .single-trip-card .main-single-trip-img {
    object-fit: cover;
    min-height: 13.75rem;
    border-radius: 0.5rem 0.5rem 0px 0px;
    width: 100%; }

.trip-img-container {
  background: #17b9bc;
  border-radius: 0.25rem;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center; }
  .trip-img-container h3, .trip-img-container .h3 {
    margin: 0;
    color: #ffffff; }
  .trip-img-container .price-label,
  .trip-img-container .price {
    color: #ffffff; }

.single-trip-inner-content a {
  color: #17b9bc; }

.year-offer-filter {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0.75rem; }
  .year-offer-filter a {
    padding: 0.5rem 0 0; }

.offers-filter {
  background: #ffffff;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem 0px 0px 0.5rem;
  padding: 1.5rem 1rem; }
  .offers-filter span {
    color: #a8a5a5; }
  .offers-filter h4, .offers-filter .h4 {
    color: #ff7a05;
    margin-bottom: 1rem; }
  .offers-filter .filter-box-inner {
    margin-bottom: 1rem; }
    .offers-filter .filter-box-inner h4, .offers-filter .filter-box-inner .h4 {
      color: #373737;
      padding: 0.5rem 0.5rem 0.5rem 0;
      margin-bottom: 2px; }

.trip-banner {
  margin: 0 0 3.5rem; }
  @media (max-width: 991.98px) {
    .trip-banner {
      margin: 0 0 2.5rem; }
      .trip-banner .col-lg-3 {
        margin-top: 2.5rem;
        padding: 0; } }
  .trip-banner .col-lg-3 {
    padding-right: 0; }

.trip-spacer {
  padding-bottom: 2rem; }

.trip-secondary-info h3, .trip-secondary-info .h3 {
  margin-bottom: 1rem; }

.trip-secondary-info p {
  margin-bottom: 1.875rem; }

.info-paragraph {
  margin-bottom: 2.5rem; }

.trip-info-card {
  background: #ffffff;
  padding: 2rem 1.5rem;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem; }
  @media (max-width: 991.98px) {
    .trip-info-card {
      margin-bottom: 2rem; } }
  @media (max-width: 575.98px) {
    .trip-info-card {
      margin: 0 -1.5rem 2rem; } }

.lightbox-container {
  margin-bottom: 3.5rem; }
  @media (max-width: 991.98px) {
    .lightbox-container {
      margin-bottom: 1.25rem; } }

#infoForm .form-control,
#purchaseForm .form-control {
  font-size: 1rem; }

#infoForm .flatpickr-input,
#purchaseForm .flatpickr-input {
  font-size: 1rem;
  max-width: 100%; }

#infoForm .input-group,
#purchaseForm .input-group {
  flex-wrap: nowrap; }

#infoForm .form-label,
#purchaseForm .form-label {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.25rem; }

#infoForm .form-control {
  border-left: none;
  padding-left: 0; }

#purchaseForm select {
  visibility: hidden; }

#purchaseForm .input-container {
  display: flex; }

@media (max-width: 991.98px) {
  #purchaseForm {
    margin-bottom: 2rem; }
    #purchaseForm .input-container {
      flex-direction: column;
      margin: 0; }
      #purchaseForm .input-container .input-container-inner {
        margin: 0; } }

.about-trip-row h3, .about-trip-row .h3 {
  margin-bottom: 1rem; }

@media (max-width: 991.98px) {
  .about-trip-row {
    flex-direction: column-reverse; } }

.amenities-row {
  padding-top: 2rem; }

.trips-inner-container {
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 2.5rem;
  margin-bottom: 4rem; }
  @media (max-width: 991.98px) {
    .trips-inner-container {
      margin: 0 -1.5rem 4rem;
      padding: 2.5rem 1.5rem 0; } }

.trip-info-list {
  list-style: none;
  max-width: 23.125rem;
  margin: 0.5rem 0 2rem;
  padding: 0; }
  .trip-info-list li {
    background: #fbfbfb;
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem; }
    .trip-info-list li:nth-child(even) {
      background: #edfafa; }
    .trip-info-list li svg {
      margin-right: 0.5rem; }
      .trip-info-list li svg path {
        fill: #17b9bc; }

.trip-warning {
  margin-bottom: 2rem;
  display: block; }

.trip-img-banner,
.trip-secondary-text-banner {
  position: absolute;
  top: 1rem;
  z-index: 1;
  display: flex;
  background: #edfafa;
  border-radius: 0.25rem; }
  .trip-img-banner p,
  .trip-secondary-text-banner p {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #17b9bc; }

.trip-img-banner {
  left: -0.5rem; }

.trip-secondary-text-banner {
  top: 5rem; }

.trip-secondary-banner {
  top: 4rem;
  left: -0.5rem;
  position: absolute;
  z-index: 1;
  display: flex;
  background: #edfafa;
  border-radius: 0.25rem; }
  .trip-secondary-banner p {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #17b9bc; }

.initial-trip-content {
  display: flex; }
  .initial-trip-content h2, .initial-trip-content .h2 {
    margin-bottom: 0.5rem; }
  @media (min-width: 992px) {
    .initial-trip-content {
      justify-content: space-between;
      align-items: center; } }
  @media (max-width: 991.98px) {
    .initial-trip-content {
      flex-direction: column-reverse; }
      .initial-trip-content .social-btn {
        margin-bottom: 2rem; }
      .initial-trip-content h2, .initial-trip-content .h2 {
        margin-bottom: 1rem; }
      .initial-trip-content .btn-left {
        margin-left: auto; } }

#infoForm .input-container {
  display: flex;
  margin: 0 -0.75rem; }
  #infoForm .input-container .input-container-inner {
    margin: 0 0.75rem; }
  @media (min-width: 992px) and (max-width: 1399.98px) {
    #infoForm .input-container {
      flex-direction: column;
      margin: 0; }
      #infoForm .input-container .input-container-inner {
        margin: 0; } }
  @media (max-width: 575.98px) {
    #infoForm .input-container {
      flex-direction: column;
      margin: 0; }
      #infoForm .input-container .input-container-inner {
        margin: 0; } }

.full-price-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem; }
  .full-price-block p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #373737;
    margin: 0; }
  .full-price-block .price {
    color: #ff7a05; }
  .full-price-block .price-banner-info {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #a8a5a5; }

.tags-container {
  display: flex;
  margin: 0 -0.5rem 2rem;
  flex-wrap: wrap; }
  .tags-container .tag-card {
    box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
    background: #ffffff;
    border-radius: 0.25rem;
    margin: 0 0.25rem 0.5rem;
    padding: 0.5rem;
    position: relative; }
    .tags-container .tag-card::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: #edfafa;
      border-radius: 0.2rem 0px 0px 0.25rem;
      width: 0.25rem;
      height: 100%; }
    .tags-container .tag-card span {
      line-height: 1rem;
      font-size: 0.75rem;
      text-decoration-line: underline;
      color: #17b9bc; }

.trip-contact-card {
  padding: 2rem;
  background: #fff4ea;
  border-radius: 0.5rem; }
  .trip-contact-card h3, .trip-contact-card .h3 {
    color: #ff7a05; }
  .trip-contact-card p {
    margin-bottom: 0.75rem; }
    @media (max-width: 575.98px) {
      .trip-contact-card p {
        margin-bottom: 0.25rem; } }

.trip-contact-inner {
  margin-top: 2rem;
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .trip-contact-inner {
      flex-direction: column;
      align-items: flex-start; } }
  .trip-contact-inner .card-link {
    display: block;
    line-height: 1.5rem; }
    .trip-contact-inner .card-link svg {
      margin-right: 0.25rem; }
  .trip-contact-inner .enquiry-link {
    color: #ff7a05;
    display: block; }

.line-spacer {
  margin: 1.5rem 0 2.5rem; }

.contact-info-row {
  width: 100%; }

.all-service-row p {
  margin-bottom: 0; }

#tripsDropdown + ul {
  border: none; }
  #tripsDropdown + ul li .nav-link {
    color: #373737; }

.saved-trips-content a {
  color: #ff7a05; }

@media (max-width: 575.98px) {
  .saved-trips-content .saved-img {
    width: 100%;
    object-fit: cover; } }

.saved-trips-info-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0.5rem; }

.inner-trip-link svg {
  margin-left: 0.25rem; }
  .inner-trip-link svg path {
    fill: #ff7a05; }

.inner-trip-link:hover svg path {
  fill: #0e0e0e; }

.lightbox .lb-image {
  object-fit: cover; }
  @media (min-width: 768px) {
    .lightbox .lb-image {
      width: 500px !important;
      height: 500px !important; } }
  @media (max-width: 767.98px) {
    .lightbox .lb-image {
      width: 100vw !important;
      height: 100% !important;
      max-height: 250px !important; } }

@media (min-width: 992px) {
  .single-filter-col {
    margin-top: 54px; } }

.filter-select {
  max-width: 200px; }

.lb-loader, .lightbox {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0; }

body.lb-disable-scrolling {
  overflow: hidden; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: .8;
  display: none; }

.lightbox {
  width: 100%;
  z-index: 10000;
  font-weight: 400;
  outline: 0; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid #fff; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  top: 43%;
  height: 25%;
  width: 100%; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==); }

.lb-next, .lb-prev {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }

.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }

.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em; }

.lb-data .lb-caption a {
  color: #4ae; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: 0;
  filter: alpha(Opacity=70);
  opacity: .7;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(Opacity=100);
  opacity: 1; }

a {
  text-decoration: none;
  color: #626161; }
  a:hover {
    color: #0e0e0e; }

h1, .h1 {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 2.5rem; }

h2, .h2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem; }

h3, .h3 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem; }

h4, .h4 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.438rem; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  color: #373737; }

body {
  font-family: DM Sans, sans-serif;
  size: 1rem;
  line-height: 1.25rem; }

.full-width-background {
  width: 100%;
  background: #fbfbfb; }

.full-width-bg-primary {
  width: 100%;
  background: #ff7a05; }

textarea {
  resize: none; }

.iti__flag {
  background-image: url("../images/flags.png"); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags@2x.png"); } }

.iti--separate-dial-code .iti__selected-flag {
  background-color: #ffffff; }

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #ffffff; }

.body-wrapper {
  overflow-x: hidden; }
