.checkout-container,
.gift-checkout-container {
  padding: 3.5rem 0 5rem;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    padding: 3.5rem 1.5rem 5rem;
  }
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $grey;
  }
}

.gift-checkout-container {
  @include media-breakpoint-down(lg) {
    padding: 3.5rem 0 5rem;
  }
}

.checkout-summary-container {
  padding: 3.5rem 0 5rem;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    //padding: 3.5rem 1.5rem 5rem;
    .checkout-additional-col:first-child {
      padding-left: 0;

    }
    .checkout-additional-col:nth-child(2) {
      padding-right: 0;
    }
  }
}

.checkout-additional-col {
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.checkout-col {
  border-radius: 0.5rem;
  @include media-breakpoint-down(lg) {
    padding: 0;
    background: $white;
    margin-bottom: 1.5rem;
  }
}
.checkout-row {
  margin: 0;
  @include media-breakpoint-down(lg) {
    margin: 0 -1.5rem;
  }
}

.checkout-content {
  padding: 2rem;
  border-radius: 0.5rem;
  background: $white;
  border-radius: 0.5rem;
  h3 {
    margin-bottom: 1.5rem;
  }
  @include media-breakpoint-down(lg) {
    padding: 2rem 1.5rem;
  }
}

.full-height-checkout-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 0.5rem;
  @include media-breakpoint-between(md, lg) {
    width: 50%;
  }
}

.checkout-img-banner {
  padding: 0.5rem;
  background: $background;
  border-radius: 0.25rem;
  p {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: $green;
  }
}
.checkout-info-outer {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1.5rem;
  p {
    max-width: 504px;
  }
  h4 {
    margin-bottom: 1.5rem;
  }
  @include media-breakpoint-down(lg) {
    margin: 1.5rem 0 0.5rem;
  }
}

.checkout-info-icons {
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem;
  svg {
    margin-right: 0.375rem;
  }
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $secondary;
  }
}
.checkout-table {
  margin: 1.5rem 0 0;
  border-spacing: 0;
  .mobile-checkout-info {
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    .mobile-checkout-info {
      display: none;
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 1.5rem 0 0;
  }
  @include media-breakpoint-down(sm) {
    thead,
    .table-border {
      display: none;
    }
    tr {
      display: flex;
      flex-direction: column;
      border: 1px solid $almost-grey;
      &:first-child {
        margin-bottom: 2rem;
      }
      td {
        display: flex;
        justify-content: space-between;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
    & > :not(caption) > * > * {
      padding: 0.875rem 0;
    }
  }
  & > :not(:first-child) {
    border-top: none;
  }
  @include media-breakpoint-up(sm) {
    & > :not(caption) > * > * {
      border-bottom-width: 0;
      padding: 0.875rem 1.5rem;
    }
  }
  thead tr p {
    font-weight: 500;
    line-height: 1.125rem;
    color: $white;
    margin: 0;
  }
  thead {
    background: $green;
  }
  td {
    position: relative;

    @include media-breakpoint-down(xl) {
      min-width: 15.125rem;
    }
  }
  td:not(:first-child) {
    text-align: center;
  }

  .form-check {
    display: flex;
    align-items: end;
    input {
      margin-right: 0.5rem;
    }
  }
  .form-check-input:checked {
    background-color: $white;
    border-color: $green;
  }
  .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    border-color: $grey;
    border-width: 2px;
  }
  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2317B9BC'/%3e%3c/svg%3e");
  }
  span,
  .form-check-label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $secondary;
  }
}

.table-wrapper {
  box-shadow: 0px 0.25rem 0.25rem rgba(216, 216, 216, 0.12);
}
.table-border {
  border: 1px solid #ececec;
  transform: rotate(90deg);
  width: 1.5rem;
  right: 0;
  top: 1.5rem;
  position: absolute;
}

.optional-checkout-fields {
  max-width: 26.875rem;
  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $secondary;
    margin-bottom: 0.25rem;
  }
  margin-top: 2rem;
  input {
    margin-bottom: 1rem;
    padding: 0.75rem 1.5rem;
    border: 1px solid $almost-grey;
    border-radius: 0.25rem;
  }
}
.terms-accept-block {
  max-width: 26.875rem;
  .form-check-input {
    border-radius: 1px;
  }
  .form-check-input:checked {
    background-color: $primary;
    border-color: $grey;
  }
}
.remaining-label {
  margin-bottom: 1.25rem;
  display: block;
  margin-top: 1rem;
}

.optional-user-fields,
.checkout-user-fields {
  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $secondary;
    margin-bottom: 0.25rem;
  }
  input {
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border: 1px solid $almost-grey;
    border-radius: 0.25rem;
  }
}

.checkout-confirm-container {
  padding: 2.5rem 0 11.25rem;
  @include media-breakpoint-down(lg) {
    padding: 5.5rem 1.5rem 8.125rem;
    margin: 0;
    max-width: 100%;
  }
}

#passengersAccordion {
  .accordion-header {
    margin-bottom: 1rem;
  }
  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
  p {
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .accordion-button {
    padding: 0.75rem 0.5rem 0.75rem 1rem;
    background: $background;
    border-radius: 0.25rem;
  }

  .accordion-button::after {
    background-image: url("../images/accordion_icon.svg");
    width: 1.5rem;
    height: 1.5rem;
  }
  .accordion-item {
    margin-bottom: 1rem;
    border: none;
  }
  .input-group {
    max-width: 126px;
  }
}
.passengers-block {
  margin-top: 3rem;
}
.passenger-number-input + .input-group {
  min-width: 7.875rem;
}
.btn-minus,
.btn-plus {
  background: $almost-grey;
  border-radius: 0.25rem;
  color: $secondary;
  border-color: transparent;
}

.summary-secondary-info {
  max-width: 46.25rem;
  @include media-breakpoint-down(lg) {
    margin: 0 1.5rem;
  }
}

.checkout-summary-content-inner {
  max-width: 34.063rem;
  p {
    margin-bottom: 2rem;
    font-size: 0.875rem;
  }
  h4 {
    color: $dark-grey;
  }
}

.checkout-service-card {
  background: $white;
  box-shadow: 0px 0.25rem 0.25rem rgba(216, 216, 216, 0.12);
  border-radius: 0.5rem;
  border: none;
  transition: all 0.3s;
  max-width: 14.375rem;
}
.checkout-services-cards {
  margin: 0 -0.75rem;
  .checkout-service-card {
    margin: 0 0 1.5rem;
  }

  .checkout-service-card-outer {
    margin: 0 0.75rem;
  }
}

.checkout-col-card {
  background: $white;
  padding: 2rem;
  @include media-breakpoint-down(lg) {
    padding: 2rem 1.5rem;
  //   margin: 0 -1.5rem;
  }
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem;
  h3 {
    color: $dark-grey;
    margin-bottom: 1.5rem;
  }
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $grey;
    font-weight: 400;
  }
  .checkout-price {
    color: $dark-grey;
  }
  .full-checkout-price {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.688rem;
    font-family: "Montserrat";
    color: $dark-grey;
  }
}
[name="paymentType"]:checked + img, [name="couponType"]:checked + div {
  outline: 2px solid $primary;
}
[name="paymentType"], [name="couponType"] {
  opacity: 0;
  position: absolute;
}

.payment-type-blocks {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;

  border: 1px solid $almost-grey;
  padding-top: 1.5rem;
  label {
    padding-bottom: 2rem;
    text-align: center;
    @include media-breakpoint-down(lg) {
      padding-bottom: 1.25rem;
    }
  }
}
.coupons-type-blocks {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(sm) {
    .col-6 {
      padding-left: 0;
    }
  }
}
.coupons-type-blocks + button {
  margin-left: 0.75rem;
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    width: 100%;
  }
}

.gift-price {
  padding: 1rem;
  background: $white;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.payment-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
  border-bottom: 1px solid $almost-grey;
  p {
    margin-bottom: 0.75rem;
  }
}

.checkout-offer-content {
  padding: 1.5rem;
  width: 100%;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  h4 {
    margin: 0 0 0.5rem;
  }
  a {
    color: $primary;
    line-height: 1.25rem;
    font-weight: 500;
  }
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
}

.checkout-service-card h3 {
  color: $primary;
}
.checkout-service-card {
  max-width: unset;
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    padding: 0 1rem;
    text-align: center;
  }
}
.service-enable-btn {
  display: inline-block;
  width: unset;
  padding: 0.875rem 2.5rem;
  margin-bottom: 1rem !important;
}