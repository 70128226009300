.send-btn {
  background: $primary;
  padding: 1rem;
  border: none;
  border-radius: 0px 0.25rem 0.25rem 0px;
}

.search-btn {
  background: $white;
  color: $black !important;
  font-weight: 500;
  padding: 1rem;
  border: none;
  border-radius: 0px 0.25rem 0.25rem 0px;
  line-height: 1.5rem;
  transition: all 0.3s;
  &:hover {
    color: $white;
    background: $light-orange;
  }
  img {
    max-width: none;
  }
  position: absolute;
  top: 0;
  right: 0;
}

.more-btn {
  background: $primary;
  color: $white;
  padding: 0.875rem 0;
  display: block;
  margin: 0 auto 1rem;
  max-width: 11.875rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
  @include media-breakpoint-up(md) {
    display: none;
  }
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
}

.return-btn {
  display: inline-block;
  background: $primary;
  border: 1px solid $primary;
  color: $white;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 0.875rem 2rem;
  margin-top: 2rem;
}
.send-inquiry-btn {
  display: block;
  width: 100%;
  background: $primary;
  line-height: 1.25rem;
  color: $white;
  font-weight: 500;
  padding: 0.875rem 0;
  &:hover {
    background: $primary;
    color: $white;
  }
}
.download-btn {
  background: $light-orange;
  font-weight: 500;
  line-height: 1.25rem;
  color: $primary;
  padding: 0.875rem 1rem;
  display: inline-block;
  img {
    margin-right: 0.5rem;
  }
}
.save-btn {
  padding: 0.875rem 0.5rem;
  display: block;
  width: 100%;

  background: $white;

  border: 1px solid $green;
  border-radius: 0.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  color: $green;
  margin-top: 0.5rem;
}

.next-step-btn {
  padding: 0.875rem 0.5rem;
  display: block;
  width: 100%;

  background: $primary;

  border: 1px solid $primary;
  border-radius: 0.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  color: $white;
  margin-top: 1.5rem;
  &:hover {
    color: $white;
  }
  a {
    color: $white;
  }
}

.buy-btn {
  background: $white;
  display: inline-block;
  min-width: 12rem;
  padding: 0.75rem 2rem;
  border: 1px solid $primary;
  border-radius: 0.25rem;
  color: $primary;
  line-height: 1.25rem;
  &:hover {
    svg path {
      fill: $black;
    }
  }
}

.submit-query-btn {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  background: $primary;
  color: $white;
  border-radius: 0.25rem;
  padding: 0.875rem 0.5rem;
  margin-left: auto;
  display: block;
  width: 50%;
  border: none;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.social-btn {
  padding: 0.75rem 1.5rem;
  color: $green;
  border: 1px solid $green;
  border-radius: 0.25rem;
  font-weight: 500;
  display: inline-block;
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    svg path {
      fill: $black;
    }
  }
}
.search-result-btn {
  padding: 1rem 1.25rem;
  display: block;
  background: $primary;
  border-radius: 4px;
  color: $white;
  font-weight: 500;
  width: 25%;
  margin: 0;
  border: 0;
  svg {
    margin-left: 0.5rem;
  }
  @include media-breakpoint-down(lg) {
    width: 50%;
    margin: 1.5rem 0;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.order-btn {
  background: $primary;
  display: block;
  width: 100%;
  color: $white;
  text-align: center;
  border: 1px solid $primary;
  border-radius: 0.25rem;
  padding: 0.875rem 0.5rem;
  margin: 1rem 0 0.5rem;
}
