.services-container {
  padding: 3.5rem 0 5.5rem;
  h1 {
    margin-bottom: 3.5rem;
  }
  @include media-breakpoint-down(lg) {
    h1 {
      margin-bottom: 2.5rem;
    }
    padding: 3.5rem 1.5rem 5.5rem;
    margin: 0;
    max-width: 100%;
  }
  span {
    // font-weight: 500;
    color: $dark-grey;
    display: block;
  }
}
.service-page-card {
  padding: 1.25rem 1rem 2rem;
  @include media-breakpoint-down(sm) {
    padding: 1.25rem 1rem 0;
  }
  background: $white;
  border-radius: 0.5rem;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  h4 {
    color: $primary;
    margin-top: 1rem;
    margin-bottom: 2.25rem;
    text-align: center;
  }
  svg {
    margin-bottom: 1rem;
  }
  p {
    text-align: center;
    &:not(:last-child) {
      margin: 0;
    }
  }
  &:hover {    
    & > img {
  transition: all 0.5s;
  }
    @include media-breakpoint-up(md) {
      .more-btn {
        margin-bottom: 0;
      }
      background: $light-orange;
      img {
        width: 6rem;
        height: 6rem;
      }
      p {
        display: none;
      }
      .more-btn {
        display: block;
        color: $white;
      }
    }
  }
}

.service-text-container {
  max-height: 390px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.personal-offer-col {
  background: $green;
  padding: 5rem 5rem 5.5rem;
  border-radius: 0px 0.5rem 0.5rem 0px;
  span,
  p,
  a {
    color: $almost-white;
  }
  h2 {
    color: $white;
    margin: 1.5rem 0 0.5rem;
  }
  @include media-breakpoint-down(lg) {
    padding: 2rem 1rem 4rem;
    border-radius: 0px 0px 0.5rem 0.5rem;
  }
}
.worker-image {
  border-radius: 50%;
  max-width: 4rem;
  max-height: 4rem;
  object-fit: cover;
  margin: 0 0.5rem 0.5rem 0;
  @include media-breakpoint-down(lg) {
    margin: 0 0.5rem 2rem 0;
  }
}

#offerForm {
  .select2-selection__rendered {
    padding-right: 70px;
  }
  .select2-container {
    @include media-breakpoint-down(lg) {
      width: 100% !important;
    }
  }
  .select2-selection {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 0.25rem 0px 0px 0.25rem;
    border-color: transparent;
  }
  .select2-selection__placeholder,
  .select2-selection__rendered {
    color: $white;
    line-height: unset;
  }
  .select2-selection__arrow {
    display: none;
  }
  margin: 1rem 0 0;
  @include media-breakpoint-down(lg) {
    margin: 1rem 0 0;
  }
  .form-control::-webkit-input-placeholder {
    color: $white;
  }
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}
.offer-section-img,
.page-section-img {
  height: 100%;
  width: 100%;
  @include media-breakpoint-down(lg) {
    object-fit: cover;
    max-height: 18.75rem;
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
  border-radius: 0.5rem 0px 0px 0.5rem;
}
.page-section-img {
  max-height: 322px;
  object-fit: cover;
  border-radius: 0 0.5rem 0.5rem 0;
}

// .service-page-card > img {
//   transition: all 0.3s;
// }

.services-inner-content {
  margin-bottom: 5.5rem;
  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    color: $green;
    margin-bottom: 1rem;
  }
  h3,
  h4,
  h5,
  h6 {
    color: $green;
  }
  p {
    margin-bottom: 2rem;
  }
  a {
    color: $primary;
    font-weight: 500;
  }
}
.services-list {
  li {
    padding-left: 0.5rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.service-card {
  padding: 1.5rem;
  height: 100%;
  background: $white;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s;
  p {
    color: $primary;
    font-weight: 500;
    line-height: 1.2rem;
    text-align: center;
    margin: 0.5rem 0 0;
  }
  svg {
    margin-bottom: 1rem;
  }
  &:hover {
    @include media-breakpoint-up(md) {
      background: $light-orange;
    }
  }
}

.all-service-row {
  .col-6 {
    margin-bottom: 1.5rem;
  }
}
.service-cards-container {
  padding: 5.5rem 0;
  @include media-breakpoint-down(lg) {
    margin: 0;
    max-width: 100%;
    padding: 4rem 1.5rem;
  }
  h2 {
    margin-bottom: 2.5rem;
  }
}

.service-pricing-table {
  margin-bottom: 2.5rem;
  tr {
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
  border-collapse: collapse;
  h4 {
    color: $green;
    margin: 0;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
    color: $secondary;
  }
  span {
    color: $dark-grey;
    font-weight: 500;
  }
  thead {
    tr th {
      border: none;
    }
  }
  tbody {
    border-top: none !important;
    tr th,
    tr td {
      box-shadow: none;
      border-bottom-width: 0;
      min-width: 9.375rem;
    }
    tr:nth-child(odd) {
      background: $background;
    }
    tr:nth-child(even) {
      background: $white;
    }
  }
  & > :not(caption) > * > * {
    padding: 1rem 1rem 1rem 0.5rem;
  }
  td {
    p {
      margin: 0;
    }
    @include media-breakpoint-down(lg) {
      vertical-align: middle;
    }
  }
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $primary;
}
.service-price {
  margin: 0.5rem 0;
}

.worker-title {
  font-weight: 500;
}

