.filter-box {
  background: $white;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem 0px 0px 0.5rem;
  padding: 2.5rem 2.5rem 0.875rem;
  @include media-breakpoint-down(lg) {
    padding: 0;
  }
  .offcanvas-header {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  span {
    color: $dark-grey;
  }
  @media (min-width: 1860px) {
    .offcanvas-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .filter-box-container {
      min-width: 8.75rem;
    }
  }
  h4 {
    margin-bottom: 1.25rem;
    color: $dark-grey;
  }
}
.filter-link-container {
  margin-bottom: 0.375rem;
  a {
    color: $dark-grey;
    display: block;
    padding-bottom: 0.75rem;
  }
  span {
    color: $grey;
    margin-left: 0.25rem;
  }
}
.filter-box-mobile,
.filter-box-single {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
  a span {
    margin-left: 0.375rem;
  }
}

.filter-box-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.filter-box-single {
  select {
      max-width: 200px;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
  }
  span {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.form-select {
  color: $dark-grey;
}
#filterCollapseLink {
  display: block;
  span {
    margin: 0;
  }
}

#filterCollapseLink[aria-expanded="true"] {
  img {
    transform: rotate(180deg);
  }
}

#filterCollapse {
  margin-top: 1.25rem;
}

.gift-collapse {
  margin-top: 4.75rem;
}
.gift-collapse-link {
  display: flex;
  align-items: center;
  svg {
    margin: 0;
    path {
      fill: $dark-grey;
    }
  }
}

.gift-collapse-link[aria-expanded="true"] {
  svg {
    transform: rotate(180deg);
  }
}
