#c-ttl,
#s-ttl {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.125rem !important;
  line-height: 1.438rem;
  color: $dark-grey;
}
#c-txt,
#c-bl {
  font-family: DM Sans, sans-serif;
  size: 1rem;
  color: $secondary;
  line-height: 1.5rem;
}
.cc_div .cc-link {
  border-bottom: 1px solid $dark-grey;
  color: $dark-grey !important;
}

#c-bns button:first-child,
#s-bns button:first-child {
  background: $primary !important;
  font-size: 1rem;
}
#s-rall-bn,
#c-s-bn {
  background: $green;
  font-size: 1rem;
  color: $white;
  &:hover {
    background: $green !important;
  }
}
#s-sv-bn {
  background: $dark-grey;
  font-size: 1rem;
  color: $white;
  &:hover {
    background: $dark-grey !important;
  }
}
#s-c-bn {
  background: $background;
  &::after,
  &::before {
    background: $dark-grey !important;
  }
}
.b-tl {
  color: $dark-grey !important;
}
#s-cnt .p {
  color: $secondary !important;
}
.c-tgl:checked ~ .c-tg,
.c-tg.c-ro {
  background: $primary !important;
}
.c-tg.c-ro {
  opacity: 0.5;
}
.c-tgl:not(:checked) ~ .c-tg {
  background: $grey;
}
@media screen and (max-width: 688px) {
  .bns-t #s-bns {
    height: 11.5em !important;
  }
}
