.header-banner {
  background-color: $almost-white;
  padding: 0.75rem 0;
  width: 100%;
  p,
  .language-code {
    margin: 0 0 0 2rem;
    font-size: 0.875rem;
    color: $dark-grey;
  }
  .language-code {
    line-height: 1rem;
  }
  @include media-breakpoint-down(xxl) {
    display: none;
  }
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  background: $white;
}
.navbar-main {
  padding: 1.125rem 0;
  width: 100%;
  display: flex;
  justify-content: end;

  flex-wrap: nowrap !important;
  @include media-breakpoint-down(xxl) {
    padding: 1.125rem 1.5rem;
    margin: 0;
    max-width: 100%;
  }
}
.navbar-nav {
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1.25rem;
  @include media-breakpoint-down(xxl) {
    display: none;
  }
}
.nav-link {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: $black;
  margin: 0 1.25rem;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
}
.dropdown-item:hover {
  background-color: $light-orange;
}
.dropdown-menu.drop-lang {
  left: 1.875rem;
  margin-top: 0.125rem;
  border: 0;
  border-radius: 0;
  min-width: unset;
  border-top: 1px solid #dfdfdf;
}

.icon-container {
  display: flex;
  margin: 0 0 0 1.5rem;
  align-items: center;
  svg {
    margin-right: 1.5rem;
  }
  @include media-breakpoint-down(xxl) {
    margin: 0;
    min-width: 7.5rem;
    align-items: flex-end;
    svg {
      margin-right: 0;
    }
  }
}

.search-mobile-icon {
  svg {
    @include media-breakpoint-up(xxl) {
      display: none;
    }
    margin: 0 1.5rem;
  }
}

.search-link {
  border: 1px solid $green;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  color: $green;
  font-weight: 500;
  line-height: 1.25rem;
  display: flex;
  span {
    text-align: center;
  }
  svg {
    margin-right: 0.5rem;
  }
  &:hover {
    svg path {
      fill: $black;
    }
  }

  @include media-breakpoint-down(xxl) {
    display: none;
  }
}
.hero-image {
  width: 100%;
  object-fit: cover;
  max-width: unset;
  object-fit: cover;
  @include media-breakpoint-down(lg) {
    min-height: 41.375rem;
  }
}
.dropdown-toggle::after {
  border: none;
  content: url("../images/dropdown_arrow.svg");
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  filter: brightness(0) saturate(100%);
}
.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}
.dropdown-toggle:after {
  transition: 0.3s;
}

#savedTrips.dropdown-toggle::after {
  display: none;
}
#savedTrips + .dropdown-menu {
  margin-top: 1.5rem;
}

.trips-collapse {
  top: 10rem !important;
  right: 0 !important;
  left: unset !important;
  svg {
    margin: 0;
  }
  @include media-breakpoint-down(xxl) {
    top: 7rem !important;
  }

  @include media-breakpoint-up(sm) {
    max-width: 28.75rem;
    border-radius: 0.5rem 0px 0px 0px;
  }
  @include media-breakpoint-down(sm) {
    transition: none;
  }
  width: 100%;
  background: $white;
  box-shadow: 0px 0px 1rem 1px rgba(140, 140, 140, 0.12);

  @include media-breakpoint-down(sm) {
    top: 0 !important;
    bottom: 0 !important;
  }
}

#savedPlansMenu {
  .next-step-btn {
    margin: 1.5rem 2rem;
    width: unset;
    @include media-breakpoint-down(sm) {
      bottom: -0.5rem;
      position: fixed;
      width: calc(100% - 3.25rem);
      margin: 1.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    max-height: 85%;
    overflow-y: auto;
  }
}

.saved-trips {
  padding: 1.5rem 2rem 0.5rem;
  @include media-breakpoint-up(sm) {
    max-height: 50vh;
  }
  @include media-breakpoint-down(sm) {
    //height: 100%;
    max-height: 85%;
    padding: 1.5rem 1.5rem 0.5rem;
  }
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.4rem;
    position: absolute;
    top: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.875rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 0.875rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $secondary;
  }
  scrollbar-color: $secondary;
  scrollbar-width: thin;
}

hr {
  color: $almost-grey;
  opacity: 1;
  width: 100%;
}
.saved-img {
  border-radius: 0.5rem 0.5rem 0px 0px;
  position: relative;
  z-index: 0;
}
.travel-img-container {
  position: absolute;
  top: 1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: -0.5rem;
}
.travel-img {
  padding: 0.25rem;
  background: #edfafa;
  border-radius: 0.25rem;
  //max-height: 1.5rem;
  svg {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    path {
    fill: $green;
  }
  }
}
.saved-circle,
.save-circle {
  background: rgba(255, 255, 255, 0.64);
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.offcanvas {
  background: $white;
  border-radius: 0.5rem 0px 0px 0.5rem;
}
.offcanvas-end {
  width: max-content !important;
}
// .offcanvas-body {
//   padding: 1.5rem;
// }
#destinationCollapseLink[aria-expanded="true"],
#languageCollapseLink[aria-expanded="true"]:not(:first-of-type) {
  img {
    transform: rotate(180deg);
  }
}
#destinationCollapseLink {
  img {
    filter: brightness(0) saturate(100%);
    transition: 0.3s;
  }
}
.important-menu-links {
  a {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;

    color: $black;
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
}
.important-menu-links,
.information-links {
  display: flex;
  flex-direction: column;
}

#destinationCollapse {
  a {
    display: block;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }
}
.information-links {
  margin-top: 2rem;
  a {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $dark-grey;
    padding-bottom: 1rem;
  }
}

.save-circle.active {
  svg path {
    fill: $primary;
  }
}
.saved-circle.active {
  svg path {
    fill: none;
  }
}
#dropdownMenuLink {
  align-items: flex-end;
}
.header-mobile-icon {
  @include media-breakpoint-up(xxl) {
    display: none;
  }
}
