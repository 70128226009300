$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1280px,
  xxxl: 1760px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1860px,
);
$themeColor: #000;
$primary: #ff7a05;
$secondary: #626161;
$black: #0e0e0e;
$dark-grey: #373737;
$light-grey: #a8a5a5;
$almost-grey: #ececec;
$almost-white: #fbfbfb;
$white: #ffffff;
$green: #17b9bc;
$light-orange: #fff4ea;
$grey: #a8a5a5;
$background: #edfafa;

$headings-font-family: 'Montserrat';
$body-color: $secondary;