.content-404 {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.container-404 {
  @include media-breakpoint-down(lg) {
    padding: 5.5rem 1.5rem 8rem;
  }
}
