.news-content-outer,
.news-card {
  background: $white;
  border-radius: 0.5rem 0px 0px 0.5rem;
}
.news-content {
  padding: 2rem 1.5rem 2rem 2rem;
  p {
    margin-bottom: 2.5rem;
    overflow: hidden;
    -webkit-line-clamp: 4;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
}
.news-typography {
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $grey;
    margin-bottom: 1rem;
    display: block;
    h4 {
      font-size: 1.5rem;
      line-height: 1.938rem;
      color: $dark-grey;
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 1.5rem;
    }
  }
}
.news-more-link {
  line-height: 1.25rem;
  font-weight: 500;
  color: $green;
  align-items: center;    
  padding-bottom: 1rem;
  svg {
    margin-left: 0.5rem;

    path {
      fill: $green;
    }
  }
  &:hover {
    svg path {
      fill: $black;
    }
  }
}
.news-cards-container {
  margin-top: 3.5rem;
}
.news-card-inner {
  padding: 1rem 0.5rem 0 1rem;
  span {
    padding-bottom: 0.25rem;
    display: block;
  }
  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
    padding-bottom: 1.5rem;
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
.news-container {
  padding: 3.5rem 0 4rem;
  .row .col-md-6 {
    margin-bottom: 1.5rem;
  }
  h1 {
    margin-bottom: 3.5rem;
  }
  @include media-breakpoint-down(lg) {
    h1 {
      margin-bottom: 2.5rem;
    }
    padding: 2.5rem 1.5rem 5.5rem;
    margin: 0;
    max-width: 100%;
  }
}
.news-list {
  padding: 0 0 3.5rem;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    margin: 0;
    padding: 0 1.5rem 3.5rem;
  }
}
