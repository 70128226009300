.footer-notes {
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  p,
  a {
    margin: 0;
    font-size: 0.875rem;
    color: $dark-grey;
  }
  p:not(:last-child) {
    margin-right: 0.75rem;
  }

  @include media-breakpoint-down(lg) {
    padding: 1.5rem 0 0;
    margin-top: 2.5rem;
    p,
    a {
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin: 2.5rem 0 0;
    padding: 1.5rem 1.5rem 0;
    max-width: 100%;
  }
}
.footer-container {
  padding: 5.5rem 0 4.125rem;
  .row {
    .col-md-6 {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(lg) {
        //padding: 0;

        margin-bottom: 3rem;
      }
      p:not(:last-child),
      a:not(:last-child),
      h4:not(:last-child) {
        padding-bottom: 0.75rem;
        margin: 0;
      }
      @include media-breakpoint-down(sm) {
        &:last-child {
          margin-bottom: 3rem;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 5.5rem 1.5rem 4.125rem;
    max-width: 100%;
  }
}
.form-inline {
  display: flex;
}
#newsletterForm {
  max-width: 18.75rem;
  input {
    background: $white;
    border: 1px solid $almost-grey;
    border-radius: 0.25rem 0px 0px 0.25rem;
    padding: 1.125rem 1rem;
  }
  margin: 1rem 0 2.75rem;
  @include media-breakpoint-down(lg) {
    margin: 1rem 0 0;
  }
}

.social-block {
  position: absolute;
  bottom: 0;
  display: block;
}

.newsletter-info {
  margin: 2.75rem 0 0.25rem;
}
