.trips-container {
  padding: 3.5rem 0 4rem;
  h1,
  h2 {
    margin-bottom: 2.5rem;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 2rem 1.5rem 4rem;
    max-width: 100%;
    h1 {
      margin-bottom: 2rem;
    }
  }
}

.single-trip-card {
  background: $white;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem 0.5rem 0px 0px;
  .main-single-trip-img {
    object-fit: cover;
    min-height: 13.75rem;
    border-radius: 0.5rem 0.5rem 0px 0px;
    width: 100%;
  }
}

.trip-img-container {
  background: $green;
  border-radius: 0.25rem;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
    color: $white;
  }
  .price-label,
  .price {
    color: $white;
  }
}

.single-trip-inner-content {
  a {
    color: $green;
  }
}

.year-offer-filter {
  a {
    padding: 0.5rem 0 0;
  }
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0.75rem;
}
.offers-filter {
  background: $white;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem 0px 0px 0.5rem;
  padding: 1.5rem 1rem;
  span {
    color: $light-grey;
  }
  h4 {
    color: $primary;
    margin-bottom: 1rem;
  }
  .filter-box-inner {
    margin-bottom: 1rem;
    h4 {
      color: $dark-grey;
      padding: 0.5rem 0.5rem 0.5rem 0;
      margin-bottom: 2px;
    }
  }
}

.trip-banner {
  margin: 0 0 3.5rem;
  @include media-breakpoint-down(lg) {
    margin: 0 0 2.5rem;
    .col-lg-3 {
      margin-top: 2.5rem;
      padding: 0;
    }
  }
  .col-lg-3 {
    padding-right: 0;
  }
}
.trip-spacer {
  padding-bottom: 2rem;
}
.trip-secondary-info {
  h3 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1.875rem;
  }
}
.info-paragraph {
  margin-bottom: 2.5rem;
}

.trip-info-card {
  background: $white;
  padding: 2rem 1.5rem;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem;

  @include media-breakpoint-down(lg) {
    margin-bottom: 2rem;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 -1.5rem 2rem;
  }
}

.lightbox-container {
  margin-bottom: 3.5rem;
  @include media-breakpoint-down(lg) {
    margin-bottom: 1.25rem;
  }
}

#infoForm,
#purchaseForm {

  .form-control {
    font-size: 1rem;
  }
  .flatpickr-input {
    font-size: 1rem;
    max-width: 100%;
  }
  .input-group {
    flex-wrap: nowrap;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 0.25rem;
  }
}
#infoForm {
  .form-control {
    border-left: none;
    padding-left: 0;
  }
}

#purchaseForm {
  select {
    visibility: hidden;
  }
  .input-container {
    display: flex;
    // margin: 0 -0.75rem;
    // .input-container-inner {
    //   margin: 0 0.75rem;
    // }
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 2rem;
    .input-container {
      flex-direction: column;
      margin: 0;
      .input-container-inner {
        margin: 0;
      }
    }
  }
}

.about-trip-row {
  h3 {
    margin-bottom: 1rem;
  }
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }
}

.amenities-row {
  padding-top: 2rem;
}

.trips-inner-container {
  background: $white;
  border-radius: 0.5rem;
  padding: 2.5rem;
  margin-bottom: 4rem;
  @include media-breakpoint-down(lg) {
    margin: 0 -1.5rem 4rem;
    padding: 2.5rem 1.5rem 0;
  }
}
.trip-info-list {
  list-style: none;
  max-width: 23.125rem;
  margin: 0.5rem 0 2rem;
  padding: 0;
  li {
    background: $almost-white;
    &:nth-child(even) {
      background: $background;
    }
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;

    svg {
      margin-right: 0.5rem;
      path {
        fill: $green;
      }
    }
  }
}
.trip-warning {
  margin-bottom: 2rem;
  display: block;
}
.trip-img-banner,
.trip-secondary-text-banner {
  position: absolute;
  top: 1rem;
  z-index: 1;
  display: flex;
  background: $background;
  border-radius: 0.25rem;
  p {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $green;
  }
}
.trip-img-banner {
  left: -0.5rem;
}
.trip-secondary-text-banner {
  top: 5rem;
}
.trip-secondary-banner {
  top: 4rem;
  left: -0.5rem;
  position: absolute;
  z-index: 1;
  display: flex;
  background: $background;
  border-radius: 0.25rem;
  p {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $green;
  }
}

.initial-trip-content {
  display: flex;
  h2 {
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    align-items: center;
  }
  @include media-breakpoint-down(lg) {
    .social-btn {
      margin-bottom: 2rem;
    }
    flex-direction: column-reverse;
    h2 {
      margin-bottom: 1rem;
    }
    .btn-left {
      margin-left: auto;
    }
  }
}

#infoForm {
  .input-container {
    display: flex;
    margin: 0 -0.75rem;
    .input-container-inner {
      margin: 0 0.75rem;
    }

    @include media-breakpoint-between(lg, xxl) {
      flex-direction: column;
      margin: 0;
      .input-container-inner {
        margin: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin: 0;
      .input-container-inner {
        margin: 0;
      }
    }
  }
}

.full-price-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $dark-grey;
    margin: 0;
  }
  .price {
    color: $primary;
  }
  .price-banner-info {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $light-grey;
  }
}
.tags-container {
  display: flex;
  margin: 0 -0.5rem 2rem;
  flex-wrap: wrap;
  .tag-card {
    box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
    background: $white;
    border-radius: 0.25rem;
    margin: 0 0.25rem 0.5rem;
    padding: 0.5rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: $background;
      border-radius: 0.2rem 0px 0px 0.25rem;
      width: 0.25rem;
      height: 100%;
    }
    span {
      line-height: 1rem;
      font-size: 0.75rem;
      text-decoration-line: underline;
      color: $green;
    }
  }
}
.trip-contact-card {
  padding: 2rem;
  background: $light-orange;
  border-radius: 0.5rem;
  h3 {
    color: $primary;
  }
  p {
    margin-bottom: 0.75rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.25rem;
    }
  }
}
.trip-contact-inner {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  .card-link {
    display: block;
    line-height: 1.5rem;
    svg {
      margin-right: 0.25rem;
    }
  }
  .enquiry-link {
    color: $primary;
    display: block;
  }
}
.line-spacer {
  margin: 1.5rem 0 2.5rem;
}
.contact-info-row {
  width: 100%;
}

.all-service-row {
  p {
    margin-bottom: 0;
  }
}
#tripsDropdown {
  & + ul {
    border: none;
    li {
      .nav-link {
        color: $dark-grey;
      }
    }
  }
}

.saved-trips-content {
  a {
     color: $primary;
  }
  @include media-breakpoint-down(sm) {
    .saved-img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.saved-trips-info-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0.5rem;
}
.inner-trip-link {
  svg {
    margin-left: 0.25rem;
    path {
      fill: $primary;
    }
  }
  &:hover {
    svg path {
      fill: $black;
    }
  }
}

.lightbox .lb-image {
  @include media-breakpoint-up(md) {
      width: 500px !important;
      height: 500px !important;
  }

  object-fit: cover;
  @include media-breakpoint-down(md) {
    width: 100vw !important;
    height: 100% !important;
    max-height: 250px !important;
  }
}
.single-filter-col {
  @include media-breakpoint-up(lg) {
     margin-top: 54px;
  }
 
}
.filter-select {
   max-width: 200px;
}