.hero-container {
  max-width: 120rem;
  padding: 0;
}

#search-trip-block {
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  border-radius: 0.5rem;
  background: $white;
  padding: 2rem 2rem 1.125rem;
  top: -5.75rem;
  @include media-breakpoint-down(xl) {
    top: -4rem;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 2rem 1.5rem 1.125rem;
  }
}

.hero-img-container {
  position: relative;
  z-index: 0;
}
.new-offers {
  padding: 0 0 3.5rem;
  h2 {
    margin: 0 0 3.5rem;
  }
  @include media-breakpoint-down(lg) {
    padding: 0 1.5rem 3.5rem;
    margin: 0;
    max-width: 100%;
    h2 {
      margin: 0 0 2rem;
    }
  }
}

.offer-card-img-block {
  background: $white;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  height: 100%;
  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
  }
}

.card-img-block-inner {
  position: relative;
  z-index: 0;
}

.offer-img-container {
  position: absolute;
  top: 1rem;
  z-index: 1;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  left: -0.5rem;
  width: 100%;
}
.offer-content {
  padding: 1rem 1.5rem 1.5rem;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  h4 {
    margin: 0 0 0.5rem;
  }
  a {
    color: $primary;
    line-height: 1.25rem;
    font-weight: 500;
  }
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
}

.offer-content-inner {
  max-width: 34.063rem;
  p {
    margin-bottom: 2rem;
        overflow: hidden;
    -webkit-line-clamp: 4;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
  h4 {
    color: $dark-grey;
  }
}

.full-height-offer-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  @include media-breakpoint-up(xxl) {
    min-width: 18.875rem;
  }
  border-radius: 0.5rem 0px 0px 0.5rem;
  @include media-breakpoint-down(lg) {
    border-radius: 0.5rem 0.5rem 0px 0px;
    max-height: 300px;
  }
}
.special-container {
  padding: 5.5rem 0 4.375rem;
  @include media-breakpoint-down(lg) {
    margin: 0;
    max-width: 100%;
    padding: 3.5rem 1.5rem 2rem;
  }
}
.special-typography {
  h2 {
    color: $white;
    @include media-breakpoint-down(lg) {
      margin-bottom: 1.5rem;
    }
  }
  .rest-trips-links {
    margin-bottom: 0.75rem;
  }
}

.saved-container {
  padding: 3.5rem 0 4rem;
  h1 {
    margin-bottom: 3.5rem;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 3.5rem 1.5rem 4rem;
    max-width: 100%;
    h1 {
      margin-bottom: 2rem;
    }
  }
}
.trip-info-outer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem 1rem;
}
.trip-info-icons {
  display: flex;
  align-items: center;
  margin: 0 0.5rem 0.5rem;
  svg {
    margin-right: 0.375rem;
  }
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $grey;
    word-break: break-all;
  }
}
.price-label {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: $green;
  margin-right: 0.5rem;
}
.price {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.688rem;
  color: $green;
}

.price-label,
.price {
  word-break: break-all;
}

.spacer {
  padding-top: 5.5rem;
}
.small-spacer {
  padding-top: 2.5rem;
}
.medium-spacer {
  padding-top: 3.5rem;
}

.links-page-container {
  padding: 3.5rem 0 5.5rem;
  h1 {
    margin-bottom: 1rem;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 3.5rem 1rem 2rem;
    max-width: 100%;
  }
}

.links-table {
  margin: 3rem 0 0;
  border-collapse: collapse;
  & > :not(:first-child) {
    border-top: none;
  }
  h4 {
    color: $dark-grey;
    margin: 0;
  }
  thead {
    tr {
      td {
        padding: 0 1rem 1.5rem 0;
      }
      td:not(:first-child) {
        padding: 0 1rem 1.5rem;
      }
    }
  }
  tbody {
    tr td:not(:last-child) {
      border-right: 1px solid $almost-grey;
    }
    td:not(:first-child) {
      padding: 1rem 1rem 1.5rem;
    }
    td {
      padding: 1rem 1rem 1.5rem 0;
      p {
        margin: 0;
        color: $secondary;
      }
      a,
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
      }
      a {
        color: $primary;
        font-weight: 500;
      }
    }
  }
  td {
    border-color: $almost-grey;
    min-width: 19rem;
  }
}
.spacing-small {
  padding-bottom: 2.5rem;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white;
}

.placeholder {
  background-color: $white;
  cursor: pointer;
}
.cc-revoke {
  display: none !important;
}

.rest-trip {
  padding: 0 0 0.75rem;
  align-items: center;
  h4 {
    color: $white;
    padding: 2.5px 0;
  }
  svg {
    margin-right: 0.5rem;
  }

}
.cc-dismiss {
  display: none !important;
}

.saved-trip-content {
  padding: 1rem 1rem 1.5rem;
  width: 100%;
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  h4 {
    margin-top: 1rem;
  }
}

#offcanvasFilterMenu {
  .offcanvas-body {
    margin-right: 1rem;
  }
  a {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.search-container {
  padding: 0;
  @include media-breakpoint-down(lg) {
    margin: 0;
    max-width: 100%;
    padding: 1.5rem 1.5rem 0;
  }
}

.search-collapse {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  padding: 0;
}

.trip-link, .trip-link-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;

  color: $dark-grey;
  svg {
    margin-left: 0.5rem;
  }
}

.trip-link-pagination {
  svg {
    transform: rotate(90deg);
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.rest-trips-links {
  display: flex;
  flex-wrap: wrap;
  a {
    display: block;

    color: $white;
    padding: 0 0 0.75rem;
  }
  flex-direction: column;
  margin-left: 1.625rem;
}

.hero-banner-block {
  position: absolute;
  padding: 0;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    justify-content: start;
    flex-direction: column;
    align-items: flex-start;
    top: 6rem;
    margin: 0;
    max-width: 100%;
    padding: 0 1.5rem;
  }
  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: 0 1.5rem;
  }
}

h1 {
  color: $white;
}

.featured-card {
  box-shadow: 0px 0px 1rem 1px rgba(162, 162, 162, 0.12);
  @include media-breakpoint-up(lg) {
    margin-left: 0.75rem;
  }
  p {
    margin: 0;
  }
  max-width: 25.625rem;
  h3 {
    color: $white;
  }

  .price {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.688rem;
  }
  .price-label {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
  }
  svg path {
    fill: $white;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 3rem;
  }
}
.featured-card-outer {
  background: $green;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
  span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $white;
  }
  svg {
    margin-right: 0.5rem;
  }
}
.featured-card-inner {
  background: $white;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 1rem;
  p {
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }
}
.long-link {
  display: flex;
  align-items: center;
  justify-content: end;
  color: $primary;
  font-weight: 500;
  svg {
    margin-left: 0.5rem;
    path {
      fill: $primary;
    }
  }
  &:hover {
    svg {
      path {
        fill: $black;
      }
    }
  }
}

.hero-title-block {
  h1 {
    color: $white;
  }
  max-width: 37.375rem;
  @include media-breakpoint-up(lg) {
    margin-right: 0.75rem;
  }
  
}

.lightbox-main-img {
  width: 100%;
  max-height: 462px;
  height: 100%;
  object-fit: cover;
  position: relative;
  @include media-breakpoint-down(lg) {
    max-height: 18.875rem;
  }
}
.lightbox-sub-img {
  width: 100%;
  object-fit: cover;
  height: 227px;
  @include media-breakpoint-down(md) {
    height: 100%;
  }
}

.banner-container {
  padding: 0;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    margin: 0;
    padding: 0 1.5rem;
  }
}
.offer-banner-container {
  padding: 0 0 3rem;
  @include media-breakpoint-down(lg) {
    padding: 0 1.5rem 3rem;
    max-width: 100%;
    margin: 0;
  }
}
.saved-plan-link.active {
  svg path {
    fill: $primary;
  }
}

#languageCollapse {
  img {
    margin: 0.5rem 0.5rem 0 0;
  }
}

.full-width-image {
  width: 100%;
  object-fit: cover;
}
.inner-title {
  margin-bottom: 1.5rem;
  text-align: center;
  @include media-breakpoint-between(lg, xxl) {
    font-size: 1.75rem !important;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.25rem !important;
    line-height: 2rem;
  }
}

.select2-container--default .select2-results>.select2-results__options {
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    width: 0.4rem;
    position: absolute;
    top: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.875rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 0.875rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $secondary;
  }
  scrollbar-color: $secondary;
  scrollbar-width: thin;
}