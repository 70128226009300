.gifts-card-container {
  padding: 3.5rem 0 8rem;
  @include media-breakpoint-up(lg) {
    .row .col-lg-6:nth-child(odd) {
      padding-left: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    max-width: 100%;
    padding: 3.5rem 1.5rem 6rem;
    // .col-lg-6 {
    //   padding: 0;
    // }
  }
  h3 {
    margin: 2rem 0 1.5rem;
  }
  h1 {
    margin-bottom: 1.5rem;
  }
  ul {
    li:not(:last-child) {
      padding-bottom: 0.75rem;
    }
  }
}

.terms-link-text {
  color: $dark-grey;
  margin: 1rem 0 2rem;
}
.terms-link {
  color: $green;
}

.gift-card {
  background: $white;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  &:not(:last-child) {
  margin-bottom: 1.5rem;
  }
  padding: 1.5rem 1rem;
  align-items: center;
}

.gift-price {
  display: flex;
  align-items: center;

  p,
  h2 {
    font-size: 1.8rem;
    color: $green;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    h2 {
    font-size: 1.5rem;
    }
  }
  p {
    margin-right: 1rem;
  }
}

.btn-decrement,
.btn-increment {
  color: $light-grey;
  font-weight: 400;
  &:active, &:hover {
    background-color: $dark-grey;
  }
}

.gift-buy-block {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(lg) {
    justify-content: space-between;
    width: 100%;
  }
  svg {
    margin-left: 0.5rem;
  }
  svg path {
    fill: $primary;
  }
  .quantity-block {
    margin-right: 1rem;
    max-width: 126px;
    button {
      background: white;
      border: 1px solid #ced4da;
      &:active, &:hover {
        background-color: $dark-grey;
      }
    }
    @include media-breakpoint-between(sm, lg) {
      max-width: 100%;
    }
  }
}
.gift-info-col {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  @include media-breakpoint-down(lg) {
    margin-bottom: 1.5rem;
    padding: 2.5rem 1.5rem;
  }
}

.gift-info-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0 1.5rem;
  background: $background;
  align-items: center;
  border-radius: 0.25rem;
  h2,
  p {
    color: $green;
    margin: 0;
  }
}

.identity-pills .nav-link.active {
  background-color: $white;
  border-radius: 0.25rem;
}
.identity-pills {
  border-radius: 0.25rem;
  background: $almost-grey;
  padding: 0.25rem;
  margin-bottom: 1.5rem;
  .nav-item {
    width: 50%;
    button {
      margin: 0;
      width: 100%;
      span {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
  .nav-link.active {
    span {
      color: $green;
    }
  }
}
