@import "variables";
@import "./select2/select2.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "nouislider/nouislider";
@import "./buttons.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./services.scss";
@import "./news.scss";
@import "./index.scss";
@import "./contacts.scss";
@import "./404.scss";
@import "./career.scss";
@import "./filter.scss";
@import "./conditions.scss";
@import "./cookies.scss";
@import "./intlTelInput.scss";
@import "./checkout.scss";
@import "./gifts.scss";
@import "./search.scss";
@import "./trips.scss";
@import "./lightbox.scss";

img {
  @extend .img-fluid;
}
a {
  text-decoration: none;
  color: $secondary;
  &:hover {
    color: $black;
  }
}

h1 {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

h2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem;
}

h3 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

h4 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.438rem;
}

h1,
h2,
h3,
h4 {
  color: $dark-grey;
}

body {
  font-family: DM Sans, sans-serif;
  size: 1rem;
  line-height: 1.25rem;
}
.full-width-background {
  width: 100%;
  background: $almost-white;
}
.full-width-bg-primary {
  width: 100%;
  background: $primary;
}

textarea {
  resize: none;
}

.iti__flag {
  background-image: url("../images/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../images/flags@2x.png");
  }
}

.iti--separate-dial-code .iti__selected-flag {
     background-color: $white;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: $white;
}

.body-wrapper {
  overflow-x: hidden;
}
