#jobAccordion {
  button:focus:not(:focus-visible) {
    background: inherit;
    color: inherit;
  }

  li {
    font-size: 0.875rem;
  }

  .accordion-item {
    border: 0;
    margin-bottom: 0.75rem;
  }

  .accordion-collapse {
    margin-top: 0.5rem;
  }

  .accordion-spacer {
    height: 0.5rem;
    width: 100%;
    background: $almost-white;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: $white;
    span {
      color: $secondary;
    }
    
  }

  .accordion-button {
    border-radius: 0.25rem;
    padding: 2rem 1rem 0;
    align-items: baseline;
    span {
      line-height: 1.25rem;
      margin-right: 0.5rem;
      font-weight: 500;
      font-family: "DM Sans", "sans-serif";
    }
    img {
      transition: all 0.2s;
    }
  }
  .accordion-button[aria-expanded="true"] {
    img {
      transform: rotate(180deg);
    }
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-body {
    ul {
      margin-bottom: 0;
    }
    .row {
      margin: 0;
    }
    padding: 1.5rem;
    @include media-breakpoint-down(lg) {
      padding: 1.5rem 1rem;
    }
    .col-lg-6 {
      padding: 0 0.5rem;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }
    p {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: $dark-grey;
    }
  }
}
.career-container {
  padding: 3.5rem 0 16rem;
  @include media-breakpoint-down(lg) {
    padding: 3.5rem 1.5rem 10rem;
    margin: 0;
    max-width: 100%;
  }
  h1 {
    margin-bottom: 3.5rem;
  }
}
.accordion-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  h4 {
    margin: 0;
    padding-bottom: 1.5rem;
  }
  span {
    padding-bottom: 2rem;
  }
}

.career-inner-content {
  max-width: 52.813rem;
  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    color: $green;
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 2.5rem;
  }
}

.job-offer-notes {
  padding: 0;
  max-width: 25.75rem;
  span {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  a {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $green;
  }
}
