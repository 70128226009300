.contact-map {
  width: 30.938rem;
  @include media-breakpoint-down(lg) {
    width: 100%;
    height: 16.875rem;
  }
}
.contacts-card {
  box-shadow: 0px 0.25rem 0.25rem rgba(216, 216, 216, 0.12);
  border-radius: 0.5rem 0px 0px 0.5rem;
  background: $white;
  padding: 1.5rem;
  max-width: 25.75rem;
  width: 100%;
  height: 100%;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
    border-radius: 0px 0px 0.5rem 0.5rem;
  }
  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    margin-bottom: 1.5rem;
    color: $dark-grey;
  }
  span,
  a {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  span:not(:last-child),
  a {
    margin-bottom: 0.75rem;
  }
  .work-time-col {
    span {
       margin-bottom: 0.2rem;
    }
   
  }
  svg {
    margin-right: 0.5rem;
    path {
      fill: $green;
    }
  }

  .work-hours-image {
    align-self: baseline;
  }
}

#contacts-pills {
  .nav-item {
    .nav-link {
      border-bottom: 3px solid $almost-grey;
      margin: 0 0 0.5rem;
      border-radius: 2px;
      color: $secondary;
    }
    .nav-link.active {
      font-weight: 500;
      border-bottom: 3px solid $green;
      color: $green;
      background: unset;
    }
  }

  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  li {
    display: inline-block;
  }
}
#contacts-pills + .tab-content {
  margin-bottom: 1.5rem;
}
#contacts-pills {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
#contacts-pills::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.contacts-inner-content {
  max-width: 56.5rem;
}
.location-info {
  margin: 2.5rem 0 1.5rem;
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }
}
.address-info {
  margin: 1.5rem 0 3rem;
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
    margin: 1.5rem 0;
  }
}

.half-width-inputs {
  display: flex;
  width: 100%;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
  input {
    width: 50%;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-down(md) {
    input {
      width: 100%;
    }
    flex-direction: column;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: $white;
  }
}

.contact-card-heading {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.938rem;
  margin-bottom: 1.5rem;
  color: $dark-grey;
}
.contact-card-sub {
  border-radius: 0.5rem;
  max-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-sub-col {
  padding-bottom: 3rem;
  margin-bottom: 3rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 1.5rem;
  }
}

.contacts-card .inquiry-form-header-text {
  font-size: 1.125rem;
  line-height: 130%;
  color: $secondary;
}
#enquiryForm, #infoForm, #purchaseForm {
  input, textarea {
    width: 100%;
    border-color: $almost-grey;
  }
  .input-group {
   flex-wrap: nowrap;
  }
  .spinners {
    @media (max-width: 336px) {
      flex-wrap: wrap;
    }
    .form-label {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    margin-bottom: 1rem;
    .input-group {
      flex-wrap: nowrap;
      input {
        padding: 0.5rem;
      }
    }
  }

  @include media-breakpoint-down(md) {
    input,
    .input-group {
      max-width: 100%;
    }
  }
  .input-group-text {
    background: $white;
    border-right: 0;
    border-color: $almost-grey;
    margin: 0;
  }
  .form-control {
    padding: 1rem;
  }
}

#enquiryForm {

  .flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
    display: none;
  }

  .spinners .form-label {
    margin-bottom: 0.75rem;
  }
  .spinners .input-group {
    max-width: 126px;
  }
  .form-control {
    margin-bottom: 1rem;
  }
  .flatpickr-input {
    padding-left: 2.75rem;
  }
}
.modal-title {
  color: $primary;
}

.modal-header {
  border: none;
  padding: 2rem;
}
.modal-body {
  padding: 0 2rem 2rem;
}

  @include media-breakpoint-down(lg) {
    .modal-body {
      padding: 0 1.5rem 2rem;
    }
    .modal-header {
      padding: 1.5rem;
    }
  }

.select2-selection {
  text-align: left;
}
.select2-selection__rendered,
.select2-selection__arrow {
  margin: 1rem;
}

.select2-container .select2-selection--single {
  height: 3.5rem;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  align-items: center;
  .flatpickr-weekday {
    justify-content: center;
  }
}
#contactModal {
  .modal-header {
    padding: 2rem 2rem 0;
    align-items: baseline;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
}

.input-img {
  position: relative;
  .input-svg {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: $primary !important;
}

.daterangepicker td.off.ends.active{
  color: $white !important;
}
@include media-breakpoint-down(sm) {
  .daterangepicker .drp-selected {
    padding-bottom: 0.5rem;
    display: block !important;
  }
}
.applyBtn {
  background-color: $primary !important;
  color: $white !important;
  border: none;
}
.modal-backdrop {
  display: none !important;
}