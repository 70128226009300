.conditions-inner-content {
  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.938rem;
    color: $green;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1.5rem;
  }
  h4 {
    margin-bottom: 2rem;
  }
}
.conditions-list-table {
  margin-bottom: 2rem;
}

.condition-page-list {
  li {
    padding-left: 0.5em;
  }
  span {
    color: $dark-grey;
    font-weight: 500;
  }
  a {
    color: $primary;
    font-weight: 500;
  }
}
.conditions-container li::marker {
  content: counters(list-item, ".") ".";
}
.conditions-container {
  padding: 3.5rem 0 4rem;
  .row .col-md-6 {
    margin-bottom: 1.5rem;
  }
  h1 {
    margin-bottom: 3.5rem;
  }
  @include media-breakpoint-down(lg) {
    h1 {
      margin-bottom: 2.5rem;
    }
    padding: 3.5rem 1.5rem 5.5rem;
    margin: 0;
    max-width: 100%;
  }
  a {
    color: $primary;
    font-weight: 500;
  }
}
